import React, { useState, useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import videoTutorial1 from "../images/XLSX_download.mp4";
import videoTutorial2 from "../images/video.mp4";
import { Button } from "@mui/material";
import Plyr from "plyr";
import "plyr/dist/plyr.css";

function XLSXVideoPopup({ videoShow, handleVideoClose }) {
  const [btnColor, setBtnColor] = useState(false);
  const [playerState, setPlayerState] = useState(null);

  const videoElement = useRef(null);
  // let player = null;

  useEffect(() => {
    // console.log(videoElement.current);
    const options = {
      controls: ["play", "progress", "fullscreen", "play-large"],
    };
    let player = new Plyr(videoElement.current, options);
    setPlayerState(player);
    // player = new Plyr(document.getElementById("video-player"), options);
    if (player !== null && videoShow) {
      // console.log(player);
      player.play();
    }

    return () => {
      if (player !== null) {
        player.destroy();
        player = null;
        // console.log("player", player);
      }
    };
  }, [videoShow]);

  const changeVideo = (newSource) => {
    if (playerState !== null) {
      // console.log("newSource", newSource);
      playerState.source = {
        type: "video",
        sources: [
          {
            src: newSource,
            type: "video/mp4",
          },
        ],
      };
      playerState.play();
    }
  };

  const handleClose = () => {
    handleVideoClose();
    setBtnColor(false);
  };

  return (
    <Modal
      show={videoShow}
      onHide={handleClose}
      centered
      backdrop="static"
      className="modal fade video-modal"
    >
      <div
        style={{ background: "#72C4F1", color: "white" }}
        className="modal-header align-items-center"
      >
        <h3
          className="modal-title font-weight-bold responsive-video-heading"
          id="exampleModalCenterTitle"
        >
          XLSX Download Video Tutorial
        </h3>
        <button
          type="button"
          className="close"
          onClick={handleClose}
          style={{ width: "70px" }}
        >
          <span aria-hidden="true">
            <svg
              className="close-img"
              xmlns="http://www.w3.org/2000/svg"
              width="81.792"
              height="81.792"
              viewBox="0 0 81.792 81.792"
              style={{ height: "auto" }}
            >
              <defs>
                <filter
                  id="aabf409156b2bcde53225f3492dda1ba"
                  x="0"
                  y="0"
                  width="81.792"
                  height="81.792"
                  filterUnits="userSpaceOnUse"
                >
                  <feOffset dy="3" input="SourceAlpha" />
                  <feGaussianBlur stdDeviation="3" result="blur" />
                  <feFlood floodOpacity="0.161" />
                  <feComposite operator="in" in2="blur" />
                  <feComposite in="SourceGraphic" />
                </filter>
              </defs>
              <g
                id="Group_36818"
                data-name="Group 36818"
                transform="translate(-4581 -421)"
              >
                <circle
                  id="Ellipse_1"
                  data-name="Ellipse 1"
                  cx="19.306"
                  cy="19.306"
                  r="19.306"
                  transform="translate(4602.591 439.291)"
                  fill="rgb(0 90 139)"
                />
                <g
                  transform="matrix(1, 0, 0, 1, 4581, 421)"
                  filter="url(#aabf409156b2bcde53225f3492dda1ba)"
                >
                  <path
                    id="aabf409156b2bcde53225f3492dda1ba-2"
                    data-name="aabf409156b2bcde53225f3492dda1ba"
                    d="M33.9,2a31.9,31.9,0,1,0,31.9,31.9A31.99,31.99,0,0,0,33.9,2ZM45.7,41.232A3.158,3.158,0,0,1,41.232,45.7L33.9,38.362,26.56,45.7a3.158,3.158,0,0,1-4.465-4.465L29.431,33.9,22.095,26.56a3.158,3.158,0,0,1,4.465-4.465L33.9,29.431l7.336-7.336A3.158,3.158,0,0,1,45.7,26.56L38.362,33.9Z"
                    transform="translate(7 4)"
                    fill="#fff"
                  />
                </g>
              </g>
            </svg>
          </span>
        </button>
      </div>
      <div className="modal-body documentModalBody d-flex pl-0 pt-0 pb-0 video-modal-body">
        <div
          className="modal-body-content col-lg-12 col-xl-2 col-md-12 col-sm-12 d-block p-0 video-modal-menu"
          // style={{
          //   borderRight: "2px solid rgb(187 193 197)",
          //   backgroundColor: "rgb(241, 239, 239)",
          // }}
        >
          <div className="menu-video-div">
            <div
              className="video-tutorial d-flex align-items-center col-xl-12 col-lg-6 col-md-6 col-sm-6"
              style={{
                backgroundColor: `${
                  !btnColor ? "rgb(0 0 48 / 85%)" : "#ffffff"
                }`,
                color: `${!btnColor ? "#ffffff" : "rgb(0, 0, 48)"}`,
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
              }}
              onClick={() => {
                // console.log("videoTutorial1", videoTutorial1);
                changeVideo(videoTutorial1);
                setBtnColor(false);
              }}
            >
              <div style={{ height: "fit-content", margin: "0 12px" }}>
                {/* <img src={icon1} alt="Number 1" /> */}
                <div
                  className="text-center video-tutorial-number"
                  style={{
                    backgroundColor: `${
                      !btnColor ? "#ffffff" : "rgb(0 0 48 / 85%)"
                    }`,
                    color: `${!btnColor ? "#000000" : "#ffffff"}`,
                  }}
                >
                  1
                </div>
              </div>
              <div>
                <div className="video-tutorial-sub-heading">
                  How to use Excel Downloads
                </div>
                {/* <div>How to use Excel Downloads</div> */}
              </div>
            </div>
            <div
              className="video-tutorial d-flex align-items-center col-xl-12 col-lg-6 col-md-6 col-sm-6"
              style={{
                backgroundColor: `${
                  btnColor ? "rgb(0 0 48 / 85%)" : "#ffffff"
                }`,
                color: `${btnColor ? "#ffffff" : "rgb(0, 0, 48)"}`,
                borderBottomLeftRadius: "10px",
                borderBottomRightRadius: "10px",
              }}
              onClick={() => {
                changeVideo(videoTutorial2);
                setBtnColor(true);
              }}
            >
              <div style={{ height: "fit-content", margin: "0 12px" }}>
                {/* <img src={icon2} alt="Number 2" /> */}
                <div
                  className="text-center video-tutorial-number"
                  style={{
                    backgroundColor: `${
                      btnColor ? "#ffffff" : "rgb(0 0 48 / 85%)"
                    }`,
                    color: `${btnColor ? "#000000" : "#ffffff"}`,
                  }}
                >
                  2
                </div>
              </div>
              <div>
                <div className="video-tutorial-sub-heading">Tutorial 2</div>
                <div>XLSX Video Tutorial 2</div>
              </div>
            </div>
          </div>
        </div>
        {/* {!btnColor ? ( */}
        <div className="modal-body-content col-xl-10 video-div-container">
          <video ref={videoElement}>
            <source id="video-player" src={videoTutorial1} type="video/mp4" />
          </video>
        </div>
        {/* ) : (
          <div className="modal-body-content col-xl-10 video-div-container">
            <video id="video-player">
              <source src={videoTutorial2} type="video/mp4" />
            </video>
          </div>
        )} */}

        <div className="video-download-btn">
          {btnColor ? (
            <Button
              variant="contained"
              className="excel-btn"
              style={{
                backgroundColor: "rgb(0 0 48)",
                opacity: "0.6",
                cursor: "not-allowed",
              }}
            >
              <React.Fragment>Continue</React.Fragment>
            </Button>
          ) : (
            <Button
              variant="contained"
              className="excel-btn"
              style={{
                backgroundColor: "rgb(0 0 48)",
              }}
              onClick={() => {
                changeVideo(videoTutorial2);
                setBtnColor(true);
              }}
            >
              <React.Fragment>Continue</React.Fragment>
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
}

export default XLSXVideoPopup;
