import { createSlice } from "@reduxjs/toolkit";

export const stockDataSlice = createSlice({
    name: "stockData",
    initialState: { data: {} },
    reducers: {
        setStockData: (state, action) => {
            state.data = action.payload
        }
    }
})

export const { setStockData } = stockDataSlice.actions;

export default stockDataSlice.reducer;