import React, { useEffect, useState } from "react";
import crossIcon from "../images/cross-icon.png";
import closeIcon from "../images/close-icon.png";
import tickIcon from "../images/15-Checked.png";
import { Modal } from "react-bootstrap";

const AlertPopup = ({
  year,
  year1,
  reportType,
  isDocument,
  show,
  handleClose,
}) => {
  const [prefferedYear, setPrefferedYear] = useState(false);
  const [isReport, setIsReport] = useState(false);
  const [isDoc, setIsDoc] = useState(false);

  useEffect(() => {
    if (year != "" && year1 != "") {
      setPrefferedYear(true);
    } else {
      setPrefferedYear(false);
    }
    if (reportType != "") {
      setIsReport(true);
    } else {
      setIsReport(false);
    }
    if (isDocument.length > 0) {
      setIsDoc(true);
    } else {
      setIsDoc(false);
    }
  }, [year, year1, reportType, isDocument]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      backdrop="static"
      className="modal fade"
    >
      <div
        style={{ background: "#FF7474", color: "white" }}
        className="modal-header"
      >
        <h5 className="modal-title" id="exampleModalCenterTitle">
          Required Selections
        </h5>
        <button
          type="button"
          className="close"
          //   data-dismiss="modal"
          //   aria-label="Close"
          onClick={handleClose}
        >
          <span aria-hidden="true">
            <img className="close-img" src={closeIcon} alt="Close-Icon" />
          </span>
        </button>
      </div>
      {/* <div className="modal-body documentModalBody">{Document_details}</div> */}
      <div className="modal-body documentModalBody">
        <div className="mb-3 modal-body-content">
          Please Make Sure You Have Completed The Following:
        </div>
        <div className="modal-document-check mb-3">
          {prefferedYear ? (
            <img className="modal-icon" src={tickIcon} alt="Tick-Icon" />
          ) : (
            <img className="modal-icon" src={crossIcon} alt="Cross-Icon" />
          )}

          <div
            className={
              prefferedYear
                ? "document-check-line"
                : "document-check-line cross-line"
            }
          >
            Select Preferred Period
          </div>
        </div>
        <div className="modal-document-check mb-3">
          {isReport ? (
            <img className="modal-icon" src={tickIcon} alt="Tick-Icon" />
          ) : (
            <img className="modal-icon" src={crossIcon} alt="Cross-Icon" />
          )}
          <div
            className={
              isReport
                ? "document-check-line"
                : "document-check-line cross-line"
            }
          >
            Select Preferred Report Type (Quarterly, Annually)
          </div>
        </div>
        <div className="modal-document-check">
          {isDoc ? (
            <img className="modal-icon" src={tickIcon} alt="Tick-Icon" />
          ) : (
            <img className="modal-icon" src={crossIcon} alt="Cross-Icon" />
          )}
          <div
            className={
              isDoc ? "document-check-line" : "document-check-line cross-line"
            }
          >
            Select Preferred Financial Statement(s)
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AlertPopup;
