import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  getCompanyYears,
  checkCompanyDocuments,
  getSingleCompany,
  requestOpenCompanyApi,
  getFinancialNews,
  userEventAPI,
} from "../API/Userapis";
import {
  Skeleton,
  Grid,
  Paper,
  Card,
  Chip,
  Divider,
  CardActionArea,
  IconButton,
  Avatar,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  getFlag,
  setDocumentUrl,
  setDocumentOpenName,
  setDocumentCompanySymbol,
  getUserToken,
} from "../API/LocalStore";
import { getRemainsVisits } from "../API/Userapis";
import Documentdetailppopup from "../popup/DocumentDetailPPopup";
import AlertPopup from "../popup/AlertPopup";
import ForumNewsItem from "../components/ForumNewsItem";
import { setcompanybycountryval } from "../reducers/CompanyByContry";
import { useDispatch } from "react-redux";
import { setheadermenuData } from "../reducers/HeaderMenuReducer";
import { GAEvenet } from "../API/GoogleAnalytics";
import { useConfirm } from "material-ui-confirm";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import el from "date-fns/esm/locale/el/index.js";
import download_icon from "../images/download_icon.png";
import video from "../images/video.mp4";
import XLSXVideoPopup from "../popup/XLSXVideoPopup";

const Companydetails = (props) => {
  let params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let company_id = params.id;
  const [documentString, setDocumentString] = useState([]);
  const [year, setYear] = useState("");
  const [reportType, setReportType] = useState("");
  const [year1, setYear1] = useState("");
  const [documentsAvailable, setDocumentsAvailable] = useState([]);
  const [CompanyYears, setCompanyYears] = useState([]);
  const [SelectedYear, setSelectedYear] = useState("");
  const [SelectedPeriod, setSelectedPeriod] = useState("annual");
  const [Companydocuments, setCompanydocuments] = useState([]);
  const [Company_country, setCompany_country] = useState("");
  const [Company_name, setCompany_name] = useState("");
  const [Company_symbol, setCompany_symbol] = useState("");
  const [Company_image, setCompany_image] = useState("");
  const [hasSeenThisCompany, sethasSeenThisCompany] = useState(false);
  const [Remains_visits, setRemains_visits] = useState(0);
  const [isDetailModalShow, setisDetailModalShow] = useState(true);
  const [ModalDocumentTitle, setModalDocumentTitle] = useState("");
  const [ModalDocumentDetails, setModalDocumentDetails] = useState("");
  const [hasMoreNews, sethasMoreNews] = useState(false);
  const [isNewsLoaded, setisNewsLoaded] = useState(false);
  const [isYearsLoaded, setisYearsLoaded] = useState(false);
  const [News, setNews] = useState([]);
  const [Delisted_date, setDelisted_date] = useState("");
  const [Last_report, setLast_report] = useState("");
  const [MaxVisits, setMaxVisits] = useState(0);
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const [videoShow, setVideoShow] = useState(false);

  const token = getUserToken();
  const base_url = `${process.env.REACT_APP_API_ENDPOINT}`;

  useEffect(() => {
    checkCompanyDocuments(company_id).then((data) => {
      setDocumentsAvailable(data);
    });
  }, []);

  const GoToDocumentView = (ref_url, doc_name) => {
    const token = getUserToken();
    if (token) {
      if (Remains_visits != 0 || hasSeenThisCompany) {
        requestOpenCompanyApi(company_id, 1);
        sethasSeenThisCompany(true);
        setDocumentUrl(ref_url);
        setDocumentOpenName(doc_name);
        console.log(Company_symbol);
        setDocumentCompanySymbol(Company_symbol);
        navigate("/pdfview");
        // window.open('https://docs.google.com/gview?url='+ref_url+'&embedded=true#:0.page.20', '_blank');
      } else {
        alert("You have not any remaining counts to see this document.");
      }
    } else
      navigate("/login", {
        state: {
          redirect_url: window.location.pathname,
        },
      });
  };

  useEffect(() => {
    dispatch(
      setheadermenuData({ currentpath: "/financials", headerfootershow: true })
    );
    GAEvenet();
    window.scrollTo(0, 0);
    getCompanyYears(company_id).then((meta) => {
      setCompanyYears(meta);
      setisYearsLoaded(true);
    });

    getSingleCompany(company_id).then((meta) => {
      setCompany_symbol(meta.SymbolTicker);
      setCompany_name(meta.Company_Name);
      setCompany_image(meta.image);
      setCompany_country(meta.Country);
      getFinancialNews(0, meta.Country, meta.SymbolTicker).then((metanews) => {
        setDelisted_date(meta.DelistingDate);
        if (metanews.data.length > 2) sethasMoreNews(true);
        setisNewsLoaded(true);
        setNews(metanews.data.slice(0, 2));
      });

      getRemainsVisits(meta.SymbolTicker).then((meta) => {
        var session_selected_year = sessionStorage.getItem("document_set_year");
        var session_selected_period = sessionStorage.getItem(
          "document_set_period"
        );
        sessionStorage.removeItem("document_set_year");
        sessionStorage.removeItem("document_set_period");

        let visited_companies = meta.visited_companies;
        let sm_arr = visited_companies.filter(
          (element, index, array) => element === company_id
        );

        var las_report_perod = meta.last_report
          .split("-")[0]
          .toLowerCase()
          .replaceAll(" ", "");
        var las_report_year = parseInt(meta.last_report.split("-")[1]);

        if (session_selected_period)
          las_report_perod = session_selected_period.toLowerCase();
        if (session_selected_year)
          las_report_year = parseInt(session_selected_year);

        // setDocumentYear(las_report_year);
        setDocumentPeriod(las_report_perod);
        setSelectedYear(las_report_year);
        checkCompanyDocuments(company_id).then((metaDocs) => {
          setCompanydocuments(metaDocs);
          setDocumentPeriod(las_report_perod);
        });
        setLast_report(meta.last_report);
        if (sm_arr.length > 0) {
          sethasSeenThisCompany(true);
        }
        setMaxVisits(meta.visit_data.max_companies);
        if (parseInt(meta.visit_data.max_companies) === 0) {
          setRemains_visits(-1);
        } else {
          setRemains_visits(meta.visit_data.remaining_visits);
        }
      });
    });
  }, []);

  useEffect(() => {
    userEventAPI(`click_company_financial_statements`);
    var session_selected_year = sessionStorage.getItem("document_set_year");
    var session_selected_period = sessionStorage.getItem("document_set_period");

    if (session_selected_year) {
      // sessionStorage.removeItem('document_set_year');
      // sessionStorage.removeItem('document_set_period');
      setSelectedYear(session_selected_year);
      getSingleCompany(company_id).then((meta) => {
        setCompany_name(meta.Company_Name);
        setCompany_image(meta.image);
        setCompany_country(meta.Country);
        getFinancialNews(0, meta.Country, meta.SymbolTicker).then(
          (metanews) => {
            setDelisted_date(meta.DelistingDate);
            if (metanews.data.length > 2) sethasMoreNews(true);
            setisNewsLoaded(true);
            setNews(metanews.data.slice(0, 2));
          }
        );
      });
      setSelectedPeriod(session_selected_period.toLowerCase());
      if (company_id)
        checkCompanyDocuments(company_id).then((metaDocs) => {
          setCompanydocuments(metaDocs);
        });
    }
  }, [company_id]);

  const setDocumentYear = (year) => {
    setSelectedYear(year);
    checkCompanyDocuments(company_id).then((meta) => {
      setCompanydocuments(meta);
    });
  };

  const setDocumentPeriod = (period) => {
    setSelectedPeriod(period);
    if (SelectedYear)
      checkCompanyDocuments(company_id).then((meta) => {
        setCompanydocuments(meta);
      });
  };

  const handleChangeYear = (event) => {
    setYear(event.target.value);
    setYear1("");
    var btns = document.querySelectorAll(".radioBtn");
    btns.forEach((item, ind) => {
      item.checked = false;
    });
  };
  const handleChangeYear1 = (event) => {
    setYear1(event.target.value);
  };
  const handleChangeQuarter = () => {
    setReportType("Q");
  };
  const handleChangeAnnual = () => {
    setReportType("A");
  };
  // const handleChangeReportType = () => {
  //   setReportType("");
  //   // var btns = document.querySelectorAll(".radioBtn");
  //   // btns.forEach((item, ind) => {
  //   //   item.checked = false;
  //   // });
  // };

  const documents = [
    {
      document_name: "Income Statement",
      document_image: "/assets/icons/IncomeStatement.png",
      document_str: "income_statement",
      info: "An income statement is a report that shows how much revenue a company earned over a specific time period (usually for a year or some portion of a year). An income statement also shows the costs and expenses associated with earning that revenue. The literal “bottom line” of the statement usually shows the company’s net earnings or losses. This tells you how much the company earned or lost over the period.Income statements also report earnings per share (or “EPS”). This calculation tells you how much money shareholders would receive if the company decided to distribute all of the net earnings for the period. (Companies almost never distribute all of their earnings. Usually they reinvest them in the business.)To understand how income statements are set up, think of them as a set of stairs. You start at the top with the total amount of sales made during the accounting period. Then you go down, one step at a time. At each step, you make a deduction for certain costs or other operating expenses associated with earning the revenue. At the bottom of the stairs, after deducting all of the expenses, you learn how much the company actually earned or lost during the accounting period. People often call this the bottom line.",
    },
    {
      document_name: "Balance Sheet",
      document_image: "/assets/icons/BalanceSheet.png",
      document_str: "balance_sheet",
      info: "A balance sheet provides detailed information about a company’s assets, liabilities and shareholders’ equity.Assets are things that a company owns that have value. This typically means they can either be sold or used by the company to make products or provide services that can be sold. Assets include physical property, such as plants, trucks, equipment and inventory. It also includes things that can’t be touched but nevertheless exist and have value, such as trademarks and patents. And cash itself is an asset. So are investments a company makes.Liabilities are amounts of money that a company owes to others. This can include all kinds of obligations, like money borrowed from a bank to launch a new product, rent for use of a building, money owed to suppliers for materials, payroll a company owes to its employees, environmental cleanup costs, or taxes owed to the government. Liabilities also include obligations to provide goods or services to customers in the future.Shareholders’ equity is sometimes called capital or net worth. It’s the money that would be left if a company sold all of its assets and paid off all of its liabilities. This leftover money belongs to the shareholders, or the owners, of the company.",
    },
    // {
    //   document_name: "Equity Statement",
    //   document_image: "/assets/icons/EquityStatements.png",
    //   document_str: "equity_statement",
    //   info: "The statement of changes in equity, sometimes called the “statement of changes in owners’ equity” or “statement of changes in shareholders’ equity,” primarily serves to report changes in the owners’ investment in the business over time. The basic components of owners’ equity are paid- in capital and retained earnings. Retained earnings include the cumulative amount of the company’s profits that have been retained in the company. In addition, non- controlling or minority interests and reserves that represent accumulated OCI items are included in equity. The latter items may be shown separately or included in retained earnings. Volkswagen includes reserves as components of retained earnings.The statement of changes in equity is organized to present, for each component of equity, the beginning balance, any increases during the period, any decreases during the period, and the ending balance. For paid- in capital, an example of an increase is a new issuance of equity and an example of a decrease is a repurchase of previously issued stock. For retained earnings, income (both net income as reported on the income statement and OCI) is the most common increase and a dividend payment is the most common decrease.",
    // },
    {
      document_name: "Cash Flow Statement",
      document_image: "/assets/icons/CashFlow.png",
      document_str: "cash_flow_statement",
      info: "Cash flow statements report a company’s inflows and outflows of cash. This is important because a company needs to have enough cash on hand to pay its expenses and purchase assets. While an income statement can tell you whether a company made a profit, a cash flow statement can tell you whether the company generated cash.A cash flow statement shows changes over time rather than absolute dollar amounts at a point in time. It uses and reorders the information from a company’s balance sheet and income statement.The bottom line of the cash flow statement shows the net increase or decrease in cash for the period. Generally, cash flow statements are divided into three main parts. Each part reviews the cash flow from one of three types of activities: (1) operating activities; (2) investing activities; and (3) financing activities.",
    },
    // {
    //   document_name: "Comprehensive Statement",
    //   document_image: "/assets/icons/ComprehensiveIncome.png",
    //   document_str: "comprehensive_statement",
    //   info: "The statement of comprehensive income is one of the five financial statements required in a complete set of financial statements for distribution outside of a corporation.The statement of comprehensive income covers the same period of time as the income statement and consists of two major sections:Net income (or net earnings) from the company's income statement Other comprehensive income, which consists of positive and/or negative amounts for foreign currency translation and hedges, and a few other items. The totals from each of the above sections are summed and are presented as comprehensive income.(If a company does not have any item that meets the criteria of other comprehensive income, the statement of comprehensive income is not required.)For a company with an item that is considered to be other comprehensive income, the statement of comprehensive income is usually a separate financial statement that is presented immediately following the income statement. (However, a company has the option to combine the income statement and the statement of comprehensive income into one continuous financial statement.)The amount of net income will cause an increase in the stockholders' equity account Retained Earnings, while a loss will cause a decrease.The amount of other comprehensive income will cause an increase in the stockholders' equity account Accumulated Other Comprehensive Income (while a negative amount will cause a decrease in Accumulated Other Comprehensive Income).",
    // },
    // {
    //   document_name: "Notes",
    //   document_image: "/assets/icons/Notes.png",
    //   document_str: "notes",
    //   info: "Also referred to as footnotes. These provide additional information pertaining to a company's operations and financial position and are considered to be an integral part of the financial statements. The notes are required by the full disclosure principle.",
    // },
    {
      document_name: "Financial Report",
      document_image: "/assets/icons/FinancialReport.png",
      document_str: "income_statement,balance_sheet,cash_flow_statement",
      // document_str: "financial_report",
      info: "An annual report is a financial summary of a company’s activities during the year along with management’s analysis of the company’s current financial position and future plans. Annual reports are prepared at the end of the fiscal year for external users to gain financial information about the inner workings of the company and what management plans to do in the future.",
    },
    // {
    //   document_name: "Annual Report",
    //   document_image: "/assets/icons/AnnualReports.png",
    //   document_str: "annual_report",
    //   info: "Download the Audited Financial Statements",
    // },
  ];

  function documentExists(documentName) {
    var flag = 0;
    documentsAvailable.map((el) => {
      if (el.name.toLowerCase() == documentName.toLowerCase()) {
        flag = 1;
      }
      if (
        documentName == "Financial Report" &&
        documentsAvailable.length == 3
      ) {
        flag = 1;
      }
    });
    if (flag == 1) {
      return true;
    } else {
      return false;
    }
    // let isExist = Companydocuments.filter((e) => {
    //   if (
    //     e.document_name == "Comprehensive Income Statement" &&
    //     documentName == "Comprehensive Statement"
    //   )
    //     return true;
    //   if (e.document_name == documentName) {
    //     return true;
    //   }
    // });
    // if (isExist.length > 0) {
    //   return true;
    // } else {
    //   return false;
    // }
  }

  const showDetailsPopUp = (document_name, event) => {
    setisDetailModalShow(true);
    setModalDocumentTitle(document_name);
    let documentData = documents.filter(
      (elem) => elem.document_name == document_name
    );
    setModalDocumentDetails(documentData[0].info);
  };

  const downloadUrlWithFile = () => {
    let documentType = "";
    documentString.map((el) => {
      documentType += `${el},`;
    });
    if (year != "" && year1 != "" && reportType != "" && documentType != "") {
      // console.log(documentType);
      setLoader(true);
      axios({
        url: `${base_url}api/webservice/export_financial_data/${company_id}/${year}/${year1}/${reportType}/?type=${documentType}`, //your url
        method: "GET",
        responseType: "blob", // important
      }).then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", `report.xlsx`); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        setLoader(false);
      });
    } else {
      handleShow();
    }
  };

  function getdocumentLink(documentName) {
    console.log(documentName);
    var data = Companydocuments.filter((e) => {
      if (e.document_name == documentName) {
        console.log(e);
        return e;
      }
    });
    if (data.length > 0) return data[0].document_link;
    else return data.document_link;
  }

  function checkboxClicked(index) {
    // let oldArr = [];
    var chk = document.querySelectorAll(".checkbox");
    chk.forEach((item, ind) => {
      if (ind == index) {
        setDocumentString((documentString) => [...documentString, item.value]);
      }
    });
  }

  function checkboxUnClicked(index) {
    var chk = document.querySelectorAll(".checkbox");
    chk.forEach((item, ind) => {
      if (ind == index) {
        let elIndex = documentString.indexOf(item.value);
        setDocumentString((oldArr) => [
          ...oldArr.slice(0, elIndex),
          ...oldArr.slice(elIndex + 1, oldArr.length),
        ]);
      }
    });
  }

  function financialReportCheckBoxClicked(index) {
    var chk = document.querySelectorAll(".checkbox");
    chk.forEach((item, ind) => {
      if (ind == index) {
        if (item.checked == false) {
          item.checked = true;
          setDocumentString([item.value]);
        } else {
          item.checked = false;
          checkboxUnClicked(index);
        }
      } else {
        item.checked = false;
      }
    });
  }

  function financialReportCheckBoxUnClicked(index) {
    var chk = document.querySelectorAll(".checkbox");
    chk.forEach((item, ind) => {
      if (documents[ind].document_name == "Financial Report") {
        item.checked = false;
        let elIndex = documentString.indexOf(item.value);
        if (elIndex > -1) {
          console.log("elIndex", elIndex);
          setDocumentString((oldArr) => [
            ...oldArr.slice(0, elIndex),
            ...oldArr.slice(elIndex + 1, oldArr.length),
          ]);
        }
      }
    });
  }

  function clickCheckbox(index) {
    var chk = document.querySelectorAll(".checkbox");
    chk.forEach((item, ind) => {
      if (ind == index) {
        if (documents[index].document_name == "Financial Report") {
          financialReportCheckBoxClicked(index);
        } else {
          financialReportCheckBoxUnClicked(index);
          if (item.checked == false) {
            item.checked = true;
            checkboxClicked(index);
          } else {
            item.checked = false;
            checkboxUnClicked(index);
          }
        }
      }
    });
    console.log(documentString);
  }

  const handleBack = () => {
    navigate(-1);
  };

  const handlegotocountry = (counr) => {
    dispatch(setcompanybycountryval(Company_country));
    navigate("/financials");
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleVideoClose = () => setVideoShow(false);
  const handleVideoShow = () => setVideoShow(true);

  return (
    <>
      <Documentdetailppopup
        Document_title={ModalDocumentTitle}
        Document_details={ModalDocumentDetails}
        isDetailModalShow={isDetailModalShow}
      />
      <AlertPopup
        year={year}
        year1={year1}
        reportType={reportType}
        isDocument={documentString}
        show={show}
        handleClose={handleClose}
      />
      <XLSXVideoPopup
        videoShow={videoShow}
        handleVideoClose={handleVideoClose}
      />
      <div className="container-fluid nopaddingcontainer">
        <div className="container nopaddingcontainer documentdetailsection">
          <div className="row">
            <div className="col-md-1">
              <Button
                style={{
                  border: "none",
                  height: "30px",
                  transform: "translateX(-6px)",
                  marginTop: "7px",
                }}
                onClick={handleBack}
              >
                <span className="back_btn_txt">
                  <img
                    alt="Back"
                    style={{ transform: "rotateZ(90deg)" }}
                    srcSet="/assets/icons/Dropdown.svg"
                  />{" "}
                  Back
                </span>
              </Button>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <Card className="section_divider company_details_period_section">
                <span>
                  <label className="labelasheading-1 mt-1 ml-2">From</label>
                  <label className="labelasheading-1 mt-1 ml-2 label-2">
                    To
                  </label>
                </span>
                <div className="yearSelectorDocument">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Year</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={year}
                      label="Year"
                      onChange={handleChangeYear}
                      className="input_box"
                    >
                      {CompanyYears.length == 0 && !isYearsLoaded ? (
                        <div className="d-flex">
                          <Skeleton
                            variant="rectangular"
                            width={70}
                            style={{ borderRadius: "20px" }}
                            height={30}
                          />
                        </div>
                      ) : (
                        CompanyYears.map(function (value, index, array) {
                          return (
                            <MenuItem
                              key={index}
                              // onClick={() => setDocumentYear(value.year)}
                              label={value.year}
                              value={value.year}
                              // variant={
                              //   SelectedYear == value.year ? "" : "outlined"
                              // }
                            >
                              {value.year}
                            </MenuItem>
                          );
                        })
                      )}
                      {isYearsLoaded && CompanyYears.length == 0 ? (
                        <span className="no_news_txt mt-0 ml-2">
                          Years records not available for this company.
                        </span>
                      ) : (
                        ""
                      )}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Year</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={year1}
                      label="Year"
                      onChange={handleChangeYear1}
                      className="input_box"
                    >
                      {CompanyYears.length == 0 && !isYearsLoaded ? (
                        <div className="d-flex">
                          <Skeleton
                            variant="rectangular"
                            width={70}
                            style={{ borderRadius: "20px" }}
                            height={30}
                          />
                        </div>
                      ) : (
                        CompanyYears.map(function (value, index, array) {
                          if (value.year >= year) {
                            return (
                              <MenuItem
                                key={index}
                                // onClick={() => setDocumentYear(value.year)}
                                label={value.year}
                                value={value.year}
                                // variant={
                                //   SelectedYear == value.year ? "" : "outlined"
                                // }
                              >
                                {value.year}
                              </MenuItem>
                            );
                          }
                        })
                      )}
                      {isYearsLoaded && CompanyYears.length == 0 ? (
                        <span className="no_news_txt mt-0 ml-2">
                          Years records not available for this company.
                        </span>
                      ) : (
                        ""
                      )}
                    </Select>
                  </FormControl>
                </div>
                <Divider className="section_divider mt-3 mb-2" />
                <label className="labelasheading mt-1 mb-0 ml-2">
                  Periodical Report
                </label>
                <div className="labelasheading-inside mt-2">
                  <label>
                    <input
                      type="radio"
                      value={reportType}
                      name="reportType"
                      onChange={handleChangeQuarter}
                      className="radioBtn"
                    />{" "}
                    Quarter
                  </label>
                  <label className="radioBtn">
                    <input
                      type="radio"
                      value={reportType}
                      name="reportType"
                      onChange={handleChangeAnnual}
                      className="radioBtn"
                    />{" "}
                    Annual
                  </label>
                  {/* <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Year</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={year1}
                      label="Year"
                      onChange={handleChangeYear1}
                      className="input_box"
                    >
                      {CompanyYears.length == 0 && !isYearsLoaded ? (
                        <div className="d-flex">
                          <Skeleton
                            variant="rectangular"
                            width={70}
                            style={{ borderRadius: "20px" }}
                            height={30}
                          />
                        </div>
                      ) : (
                        CompanyYears.map(function (value, index, array) {
                          if (value.year >= year) {
                            return (
                              <MenuItem
                                key={index}
                                // onClick={() => setDocumentYear(value.year)}
                                label={value.year}
                                value={value.year}
                                // variant={
                                //   SelectedYear == value.year ? "" : "outlined"
                                // }
                              >
                                {value.year}
                              </MenuItem>
                            );
                          }
                        })
                      )}
                      {isYearsLoaded && CompanyYears.length == 0 ? (
                        <span className="no_news_txt mt-0 ml-2">
                          Years records not available for this company.
                        </span>
                      ) : (
                        ""
                      )}
                    </Select>
                  </FormControl> */}
                  {/* <GetQuarter
                    year={year}
                    year1={year1}
                    quarter={quarter}
                    quarter1={quarter1}
                    setQuarter1={setQuarter1}
                    handleChangeQuarter1={handleChangeQuarter1}
                  /> */}
                </div>
                <Divider className="section_divider mt-2" />
                <label className="labelasheading mt-1 ml-2">
                  Steps to Download Excel File
                </label>
                <div className="row labelasheading-inside">
                  <div className="divSteps mt-2">
                    <div className="divStep">STEP 1:</div>
                    <div className="divSteps-in">
                      Choose the period for which you want to download your
                      Excel report.
                    </div>
                  </div>
                  <div className="divSteps divStepsColor mt-3">
                    <div className="divStep">STEP 2:</div>
                    <div className="divSteps-in">
                      Click to choose the report type, either quarterly or
                      annual.
                    </div>
                  </div>
                  <div className="divSteps mt-3">
                    <div className="divStep">STEP 3:</div>
                    <div className="divSteps-in">
                      Click on the financial statement(s) you would like to
                      download.
                    </div>
                  </div>
                  <div className="divSteps divStepsColor mt-3">
                    <div className="divStep">STEP 4:</div>
                    <div className="divSteps-in">
                      Click on the "Download Excel Report" button for the report
                      to start downloading.{" "}
                    </div>
                  </div>
                </div>
              </Card>
            </div>
            <div className="col-md-8">
              <Card className="section_divider company_details_document_section">
                <div className="row company__country_div_details">
                  <div className="col-md-10">
                    <div className="company_details_company_div float-left row">
                      <div className="col-md-2 col-2">
                        <img src={Company_image} />
                      </div>
                      <div className="col-md-10 col-10">
                        <span className="tox_company_name">{Company_name}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="country_div_in_f_popup float-right">
                      <Chip
                        style={{ border: "none" }}
                        onClick={handlegotocountry}
                        avatar={
                          <Avatar
                            alt={Company_country ? Company_country[0] : "A"}
                            src={getFlag(Company_country)}
                          />
                        }
                        label={Company_country}
                        variant="outlined"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 company_details_visits">
                    {Delisted_date ? (
                      <span style={{ color: "red" }} className="redx">
                        Delisted on : {Delisted_date}
                      </span>
                    ) : (
                      ""
                    )}
                    {Last_report ? (
                      <span style={{ color: "#222b" }}>
                        Last Released Report: {Last_report}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-md-12">
                    <Grid container spacing={2}>
                      {documents.map(function (value, index, array) {
                        return (
                          <Grid item xs={6} md={3} key={index}>
                            <CardActionArea
                              onClick={() =>
                                !token
                                  ? navigate("/login", {
                                      state: {
                                        redirect_url: window.location.pathname,
                                      },
                                    })
                                  : documentExists(value.document_name)
                                  ? clickCheckbox(index)
                                  : false
                              }
                            >
                              <Paper
                                className="document_type_card p-1"

                                //documentExists(value.document_name) ? GoToDocumentView(getdocumentLink(value.document_name), value.document_name) : false)}
                              >
                                <div className="documentTypeCa4rdImage">
                                  <img
                                    src={value.document_image}
                                    alt="Cash Flow"
                                    srcSet={value.document_image}
                                  />
                                </div>
                                <div className="document_type_card_title">
                                  <input
                                    type="checkbox"
                                    className="checkbox"
                                    value={value.document_str}
                                    onClick={() =>
                                      !token
                                        ? navigate("/login", {
                                            state: {
                                              redirect_url:
                                                window.location.pathname,
                                            },
                                          })
                                        : documentExists(value.document_name)
                                        ? clickCheckbox(index)
                                        : false
                                    }
                                  />
                                  {value.document_name}
                                </div>
                                {!token ||
                                documentExists(value.document_name) ? (
                                  ""
                                ) : (
                                  <div className="noDocumentAvailableCard">
                                    <span> Not Available</span>
                                  </div>
                                )}
                              </Paper>
                              <div className="companyDetailButtonBox">
                                <div
                                  className="com_details_lock_icon"
                                  aria-label="delete"
                                >
                                  <img
                                    src={
                                      hasSeenThisCompany || Remains_visits != 0
                                        ? "/assets/icons/unlock_black.png"
                                        : "/assets/icons/lock_black.png"
                                    }
                                  />
                                </div>
                                <div
                                  data-toggle="modal"
                                  data-target="#documentDetailPoppup"
                                  onClick={(event) =>
                                    showDetailsPopUp(value.document_name, event)
                                  }
                                  className="com_details_exclamatoy_icon"
                                  aria-label="delete"
                                >
                                  <img src="/assets/icons/information.svg" />
                                </div>
                              </div>
                            </CardActionArea>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </div>
                </div>
                {/* <Toast
                  onClose={() => setShow(false)}
                  show={show}
                  delay={3000}
                  animation
                  autohide
                  className="d-inline-block m-1 toast-err"
                >
                  <Toast.Body className="toast-text-color">
                    {toastMsg}
                  </Toast.Body>
                </Toast> */}
                {/* <div className="toast-err">
                  <div className="toast-alert-div">
                    <img
                      className="alert-img"
                      src={alertIcon}
                      alt="alert-icon"
                    />
                  </div>
                  <div className="toast-heading">
                    Please Make Sure You Have Completed The Following
                  </div>
                  <div>
                    <div>
                      <img src={tickIcon} alt="Tick-icon" />
                      <div>Select Preferred Period</div>
                    </div>
                  </div>
                </div> */}
                {/* <h5 className="pt-4 labelasheading">
                  Tutorial for xlsx download
                </h5>
                <div className="pt-2">
                  <video
                    height="250"
                    // width="530"
                    style={{ maxWidth: "100%" }}
                    // style="width:675px;max-width:100%"
                    controls
                    controlslist="nodownload noplaybackrate"
                    // src="file:///C:\Ashom\ashom-web\src\images/video.mp4"
                    disablePictureInPicture
                    // src="https://media.istockphoto.com/id/1455772765/video/waterfall-with-fresh-water-in-the-romantic-and-idyllic-tropical-jungle-rainforest-blue.mp4?s=mp4-640x640-is&k=20&c=-ufHs0M4TG0HCyntsf3RwpHP08EEtAlSv8APcZe6Ciw="
                  >
                    <source src={video} type="video/mp4" />
                  </video>
                </div> */}
                <div className="tutorial-watch-btn">
                  <Button
                    variant="contained"
                    className="excel-btn"
                    onClick={handleVideoShow}
                    style={{
                      backgroundColor: "#000030",
                    }}
                  >
                    {/* {loader ? (
                      <div className="d-flex align-items-center">
                        <Spinner animation="border" />
                        <span className="spinner-text">Generating...</span>
                      </div>
                    ) : ( */}
                    <React.Fragment>
                      {/* <img
                        className="pr-2"
                        src={download_icon}
                        alt="download"
                      /> */}
                      Watch Tutorial
                    </React.Fragment>
                    {/* )} */}
                  </Button>
                </div>
                <div className="download_btn">
                  <Button
                    variant="contained"
                    className="excel-btn"
                    onClick={downloadUrlWithFile}
                    style={{
                      backgroundColor: `${loader ? " #72C4F1 " : "#000030"}`,
                    }}
                  >
                    {loader ? (
                      <div className="d-flex align-items-center">
                        <Spinner animation="border" />
                        <span className="spinner-text">Generating...</span>
                      </div>
                    ) : (
                      <React.Fragment>
                        <img
                          className="pr-2"
                          src={download_icon}
                          alt="download"
                        />
                        Download Excel Report
                      </React.Fragment>
                    )}
                  </Button>
                </div>
                <div className="company_details_visits remaining_count_detail_page">
                  {Remains_visits !== -1 ? (
                    <span style={{ color: "#001B3D" }}>
                      Remaining count : {Remains_visits} of {MaxVisits}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Companydetails;
