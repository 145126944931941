import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import {
  Button,
  Typography,
  Box,
  Grid,
  ButtonGroup,
  Stack,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
} from "@mui/material";
import { createTheme, styled, ThemeProvider } from "@mui/material/styles";
import ForwardIcon from "@mui/icons-material/Forward";
import Newsitem from "../components/NewsItem";
import { useDispatch, useSelector } from "react-redux";
import {
  allcompaniesApi,
  getCountries,
  getFinancialNews,
  getFinancialNewsPost,
  userEventAPI,
} from "../API/Userapis";
import { getSearch, setSearch } from "../API/LocalStore";
import { useEffect } from "react";
import { setheadermenuData } from "../reducers/HeaderMenuReducer";
import { GAEvenet } from "../API/GoogleAnalytics";
import axios from "axios";
import moment from "moment-timezone";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { setStockData } from "../reducers/StockDataReducer";
import { ExpandMore } from "@mui/icons-material";

const PriceDetailBox = styled(Box)(({ theme }) => ({
  background: "#FFFFFF",
  border: "1px solid #D9D9D9",
  borderRadius: "25px",
  padding: "16px 14px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

const BankNameTypo = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: "18px",
  color: "#000000",
}));

const SRTypo = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: "22px",
  color: "#000000",
}));

const ValueTypo = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: "13px",
  color: "#FF3B30FF",
}));

const ValueTypoGreen = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: "13px",
  color: "#34C759FF",
}));

const DateTypo = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: "13px",
  color: "#686868",
}));

const BankDetailTypo = styled(Typography)(({ theme }) => ({
  fontSize: "13px",
  color: "#686868",
}));

const FinancialReportBox = styled(Box)(({ theme }) => ({
  background: "#FFFFFF",
  border: "1px solid #D9D9D9",
  borderRadius: "25px",
  padding: "16px 14px",
  // display: 'flex',
  // justifyContent: 'space-between',
  // alignItems: 'center'
}));

const BoxRow = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-evenly",
}));

const Heading = styled(Typography)(({ theme }) => ({
  color: "#ABABAB",
}));

const HeadingValue = styled(Typography)(({ theme }) => ({
  // display: 'flex'
}));

const ColonBox = styled(Box)(({ theme }) => ({
  color: "#ABABAB",
}));

const HeadingTypo = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: "16px",
  marginBottom: "10px",
  cursor: "pointer",
  textAlign: "end",
}));

const ConvertGSTDate = (date, format) => {
  return moment(date).tz("Asia/Dubai").format(format);
};

const StockPriceDetail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const stockData = useSelector((state) => state?.stockData?.data);
  const [stockDetail, setStockDetail] = useState({});
  const [currentNewsPosition, setcurrentNewsPosition] = useState(0);
  const [News, setNews] = useState([]);
  const [selectedCountry, setselectedCountry] = useState("");
  const [Countries, setCountries] = useState([]);
  const [hasmorenews, sethasmorenews] = useState(true);
  const [SearchText, setSearchText] = useState("");
  const [isSearching, setisSearching] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const shareBtnModalShow = useSelector(
    (state) => state.sharebtnmodal.modalShow
  );
  const [graphFilterValue, setGraphFilterValue] = useState({
    day: 0,
    week: 0,
    month: 0,
    year: 5,
  });
  const [graphLineData, setGraphLineData] = useState([]);
  const [graphBarData, setGraphBarData] = useState([]);
  const [graphDate, setGraphDate] = useState([]);
  const [graphAllDate, setGraphAllDate] = useState([]);
  const [graphColor, setGraphColor] = useState("");
  const [graphCurrentData, setGraphCurrentData] = useState({
    start: ConvertGSTDate(moment().subtract(0, "days"), "DD MMM YYYY"),
    end: null,
  });
  const [allGraphData, setAllGraphData] = useState({
    graphAllDate: [],
    graphDate: [],
    graphLineData: [],
    graphBarData: [],
    graphColor: "",
    refresh: false,
  });

  const theme = createTheme({
    palette: {
      primary: {
        main: "#FF2503",
        contrastText: "#000000",
      },
      secondary: {
        main: "#F8F8F8",
        contrastText: "#FF2503",
      },
    },
  });

  const getCountry = (data) => {
    if (data?.includes(".SE")) {
      return "KSA";
    } else if (
      data?.includes(".AD") ||
      data?.includes(".DU") ||
      data?.includes(".DI")
    ) {
      return "UAE";
    } else if (data?.includes(".OM")) {
      return "Oman";
    } else if (data?.includes(".KW")) {
      return "Kuwait";
    } else if (data?.includes(".BH")) {
      return "Bahrain";
    } else if (data?.includes(".QA")) {
      return "Qatar";
    }
    return "";
  };

  useEffect(() => {
    setStockDetail(
      JSON.parse(getSearch("stockDetail"))?.find(
        (item) => getSearch("ticker") == item?.["RIC"]
      )
    );
    if ((getSearch("stockData") && Object.keys(stockData).length) == 0) {
      getMapData(JSON.parse(getSearch("stockData"))?.Identifier);
      dispatch(setStockData(JSON.parse(getSearch("stockData"))));
    }
  }, [stockData]);

  const getDateDetail = (data) => {
    if (data.day == 1) {
      return "";
    } else if (data.week == 1) {
      return "Past Week";
    } else if (data.month == 1) {
      return "Past Month";
    } else if (data.month == 6) {
      return "Past 6 Months";
    } else if (data.year == 1) {
      return "Past 1 Year";
    } else if (data.year == 5) {
      return "Past 5 Years";
    }
  };

  const getSpread = (data) => {
    if (data.day == 1) {
      return "A";
    } else if (data.week == 1) {
      return "A";
    } else if (data.month == 1) {
      return "A";
    } else if (data.month == 6) {
      return "E";
    } else if (data.year == 1) {
      return "E";
    } else if (data.year == 5) {
      return "E";
    }
    return "E";
  };

  const getMapData = (Identifier) => {
    axios
      .get(
        `https://refinitive.ashom.app/get/history?days=${
          graphFilterValue.day
        }&weeks=${graphFilterValue.week}&months=${
          graphFilterValue.month
        }&years=${graphFilterValue.year}&spread=${getSpread(
          graphFilterValue
        )}&stockname=${Identifier}`
      )
      .then((response) => {
        let newMapLineArray = [];
        let newMapBarArray = [];
        let newMapArrayDate = [];
        let graphDates = {};
        response.data.forEach((element) => {
          newMapLineArray.push(Number(element?.Last_price));
        });
        response.data.forEach((element) => {
          newMapBarArray.push(Number(element?.Volume));
        });
        response.data.forEach((element) => {
          if (graphFilterValue.week == 1) {
            graphDates = {
              start: ConvertGSTDate(
                moment().subtract(7, "days"),
                "DD MMM YYYY"
              ),
              end: ConvertGSTDate(moment().subtract(0, "days"), "DD MMM YYYY"),
            };
            newMapArrayDate.push(
              ConvertGSTDate(moment(element?.Date_Time + "Z"), "DD MMM")
            );
          } else if (graphFilterValue.month == 1) {
            graphDates = {
              start: ConvertGSTDate(
                moment().subtract(1, "months"),
                "DD MMM YYYY"
              ),
              end: ConvertGSTDate(
                moment().subtract(0, "months"),
                "DD MMM YYYY"
              ),
            };
            newMapArrayDate.push(
              ConvertGSTDate(moment(element?.Date_Time + "Z"), "DD MMM")
            );
          } else if (graphFilterValue.month == 6) {
            graphDates = {
              start: ConvertGSTDate(
                moment().subtract(6, "months"),
                "DD MMM YYYY"
              ),
              end: ConvertGSTDate(
                moment().subtract(0, "months"),
                "DD MMM YYYY"
              ),
            };
            newMapArrayDate.push(
              ConvertGSTDate(moment(element?.Date_Time + "Z"), "DD MMM")
            );
          } else if (graphFilterValue.year == 1) {
            graphDates = {
              start: ConvertGSTDate(
                moment().subtract(1, "years"),
                "DD MMM YYYY"
              ),
              end: ConvertGSTDate(moment().subtract(0, "years"), "DD MMM YYYY"),
            };
            newMapArrayDate.push(
              ConvertGSTDate(moment(element?.Date_Time + "Z"), "MMM YYYY")
            );
          } else if (graphFilterValue.year == 5) {
            graphDates = {
              start: ConvertGSTDate(
                moment().subtract(5, "years"),
                "DD MMM YYYY"
              ),
              end: ConvertGSTDate(moment().subtract(0, "years"), "DD MMM YYYY"),
            };
            newMapArrayDate.push(
              ConvertGSTDate(moment(element?.Date_Time + "Z"), "YYYY")
            );
          } else if (graphFilterValue.day == 1) {
            graphDates = {
              start: ConvertGSTDate(
                moment().subtract(0, "days"),
                "DD MMM YYYY"
              ),
              end: null,
            };
            newMapArrayDate.push(
              ConvertGSTDate(moment(element?.Date_Time + "Z"), "hh a")
            );
          }
        });
        // setGraphCurrentData(graphDate)

        // setGraphAllDate(response.data)
        // setGraphDate(newMapArrayDate)
        // setGraphLineData(newMapLineArray)
        // setGraphBarData(newMapBarArray)
        // setGraphColor(newMapLineArray[0] > newMapLineArray[newMapLineArray.length - 1] ? 'red' : 'green')
        setAllGraphData({
          graphAllDate: response.data,
          graphDate: newMapArrayDate,
          graphLineData: newMapLineArray,
          graphBarData: newMapBarArray,
          graphColor:
            newMapLineArray[0] > newMapLineArray[newMapLineArray.length - 1]
              ? "red"
              : "green",
          refresh: !allGraphData.refresh,
        });
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("responsemapDataerrr", error);
      });
  };

  useEffect(() => {
    if (stockData?.Identifier) getMapData(stockData?.Identifier);
  }, [graphFilterValue]);

  // const getGraphData = async () => {
  //   const config = {
  //     method: 'get',
  //     url: 'http://13.127.45.140/stock/getall',
  //     headers: {}
  //   };

  //   await axios(config)
  //     .then(function (response) {
  //       // setGraphLineData(response.data);
  //     })
  //     .catch(function (error) {

  //     });
  // }

  // useEffect(() => {
  //   getGraphData();
  // }, [])

  // function changeCountry(country = 'KSA') {
  //   userEventAPI(`view_${country.toLowerCase()}_news`);
  //   setSearch('newssearchcountry', country);
  //   setSearch('newssearch', '');
  //   setcurrentNewsPosition(0)
  //   setselectedCountry(country);
  //   setSearchText("");
  //   getFinancialNewsPost(0, country, "", 0).then(meta => {
  //     setNews(meta.data)
  //     setcurrentNewsPosition(1);
  //   });
  // }

  function fetchNews(searchext = 0, cp = -1) {
    setisSearching(true);
    let selectedCountry = getSearch("ticker")
      ? getCountry(getSearch("ticker"))
      : getCountry(stockData?.Identifier);
    let selectedName = getSearch("stokeTicker")
      ? getSearch("stokeTicker")
      : stockData?.["SymbolTicker"] ?? stockDetail?.SymbolTicker;
    getFinancialNewsPost(
      cp != -1 ? cp : currentNewsPosition,
      selectedCountry,
      selectedName,
      searchext
    ).then((meta) => {
      if (searchext !== 0) {
        setNews(meta.data);
      } else {
        setNews([...News, ...meta.data]);
      }
      console.log(
        "meta.me--------------- tadata.total_pages == meta.metadata.current_page",
        meta.metadata.total_pages == meta.metadata.current_page
      );
      if (meta.metadata.total_pages == meta.metadata.current_page)
        sethasmorenews(false);
      if (parseInt(meta.metadata.total_pages) <= 0) sethasmorenews(false);
      setIsFetching(false);
      setcurrentNewsPosition(currentNewsPosition + 1);

      setisSearching(false);
    });
  }

  const handleSearch = (Searchvalue) => {
    setSearch("newssearch", Searchvalue);
    setSearchText(Searchvalue);
    setNews([]);
    setcurrentNewsPosition(0);
    fetchNews(Searchvalue, 0);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    userEventAPI(`view_countries_news`);
    dispatch(
      setheadermenuData({ currentpath: "/news", headerfootershow: true })
    );
    GAEvenet();
    getCountries().then((meta) => {
      setCountries(meta);
      let searchtext = getSearch("newssearch") ?? "";
      let selectedCountry = getSearch("ticker")
        ? getCountry(getSearch("ticker"))
        : getCountry(stockData?.Identifier);
      let selectedName = getSearch("stokeTicker")
        ? getSearch("stokeTicker")
        : stockData?.["SymbolTicker"] ?? stockDetail?.SymbolTicker;
      setselectedCountry(selectedCountry);
      getFinancialNewsPost(0, selectedCountry, selectedName, searchtext).then(
        (meta) => {
          setNews(meta.data);
          console.log(
            "meta.metadata.total_pages == meta.metadata.current_page",
            meta.metadata.total_pages == meta.metadata.current_page
          );
          if (meta.metadata.total_pages == meta.metadata.current_page)
            sethasmorenews(false);
          if (parseInt(meta.metadata.total_pages) <= 0) sethasmorenews(false);
          setIsFetching(false);
          setcurrentNewsPosition(currentNewsPosition + 1);
          setisSearching(false);
        }
      );
    });

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [stockData, stockDetail]);

  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    if (!isFetching || !hasmorenews) return;
    fetchNews();
  }, [isFetching]);

  function handleScroll() {
    if (hasmorenews) {
      if (
        window.innerHeight +
          (parseInt(document.documentElement.scrollTop) + 2000) <
          document.documentElement.offsetHeight ||
        isFetching
      )
        return;
      setIsFetching(true);
    }
  }

  const handleBack = () => {
    navigate(-1);
  };

  let base = +new Date(1968, 9, 3);
  let oneDay = 24 * 3600 * 1000;
  let date = [];
  let data = [Math.random() * 300];
  for (let i = 1; i < 20000; i++) {
    var now = new Date((base += oneDay));
    date.push([now.getFullYear(), now.getMonth() + 1, now.getDate()].join("/"));
    data.push(Math.round((Math.random() - 0.5) * 20 + data[i - 1]));
  }

  function formatTooltip(
    tooltip,
    x = this.x,
    y = this.y,
    points = this.points
  ) {
    let s = ``;
    let currentIndex = points?.[0]?.point?.index;
    let data = allGraphData?.graphAllDate[currentIndex];
    s += `${ConvertGSTDate(
      moment(data?.Date_Time + "Z"),
      "DD MMM, YYYY hh a"
    ).toUpperCase()} GST<br/><span style="color:${
      points?.[0]?.color
    }">\u25CF</span> <b>Price</b>: <b>${
      data?.Last_price
    }</b> <span style="color:${
      data?.price_difference_previous_entry > 0 ? "#34C759FF" : "#FF3B30FF"
    }"><b>${data?.price_difference_previous_entry} (${
      data?.percentage_change_previous_entry
    }%)</b></span><br/><span style="color:${
      points?.[1]?.color
    }">\u25CF</span> <b>Volumn</b>: <b>${data?.Volume}</b>`;
    return s;
  }

  const options = useMemo(() => {
    const getColumnWidth = (data) => {
      let size = 1;
      let customLength = 10;
      for (let i = 10; i >= 1; i--) {
        if (data.length <= customLength) {
          size = i;
          break;
        }
        customLength += 10;
      }
      return size;
    };
    const getColumnStep = (data) => {
      return data.length > 7
        ? Math.round(data.length / 7) > 25
          ? Math.round(data.length / 7) + 10
          : (Math.round(data.length / 7) > 50
              ? Math.round(data.length / 7) + 20
              : Math.round(data.length / 7)) + 5
        : 1;
    };

    const option = {
      rangeSelector: {
        selected: 1,
      },

      title: {
        text: "Stock Price",
      },

      navigator: {
        series: {
          accessibility: {
            exposeAsGroupOnly: true,
          },
        },
      },

      tooltip: {
        shared: true,
        formatter: formatTooltip,
      },

      plotOptions: {
        series: {
          lineWidth: 1.5,
          pointWidth: getColumnWidth(allGraphData?.graphBarData ?? []),
          marker: {
            enabled: false,
          },
          states: {
            hover: {
              lineWidthPlus: 0,
            },
            normal: {
              animation: false,
            },
          },
        },
      },

      xAxis: {
        categories: allGraphData?.graphDate ?? [],
        tickInterval: getColumnStep(allGraphData?.graphDate ?? []),
        labels: {
          align: "left",
        },
        crosshair: {
          width: 0.4,
          color: "#aeaeb2ff",
          height: 50,
        },
      },

      yAxis: [
        {
          labels: {
            align: "right",
            x: -3,
          },
          title: {
            text: "Price",
          },
          botoom: "15%",
          height: "85%",
          //height: '60%',
          lineWidth: 2,
          visible: false,
          resize: {
            enabled: true,
          },
        },
        {
          labels: {
            align: "right",
            x: -3,
          },
          title: {
            text: "Volume",
          },
          top: "85%",
          height: "15%",
          offset: 0,
          lineWidth: 2,
          visible: false,
        },
      ],
      series: [
        {
          name: "Price",
          data: allGraphData?.graphLineData ?? [],
          type: "area",
          threshold: null,
          grouping: true,
          showInLegend: false,
          tooltip: {
            valueDecimals: 2,
          },
          color: allGraphData?.graphColor == "red" ? "#EB0F29" : "#00873C",
          fillColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1,
            },
            stops: [
              [
                0,
                allGraphData?.graphColor == "red"
                  ? "rgba(235,15,41, 0.59)"
                  : "rgba(0,135,60, 0.59)",
              ],
              [
                1,
                allGraphData?.graphColor == "red"
                  ? "rgba(235,15,41, 0.0)"
                  : "rgba(0,135,60, 0.0)",
              ],
            ],
          },
        },
        {
          name: "Volumn",
          data: allGraphData?.graphBarData ?? [],
          type: "column",
          grouping: true,
          showInLegend: false,
          threshold: null,
          tooltip: {
            valueDecimals: 2,
          },
          yAxis: 1,
          color: "#aeaeb2ff",
          fillColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1,
            },
            stops: [
              [0, Highcharts.getOptions().colors[0]],
              [
                1,
                Highcharts.color(Highcharts.getOptions().colors[0])
                  .setOpacity(0)
                  .get("rgba"),
              ],
            ],
          },
        },
      ],
    };
    return option;
  }, [allGraphData?.refresh]);

  const getCurrency = (data) => {
    if (data?.includes(".SE")) {
      return "SR";
    } else if (data?.includes(".AD") || data?.includes(".DU")) {
      return "AED";
    } else if (data?.includes(".OM")) {
      return "OMR";
    } else if (data?.includes(".KW")) {
      return "KD";
    } else if (data?.includes(".BH")) {
      return "BD";
    } else if (data?.includes(".QA")) {
      return "QAR";
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <div style={{ minHeight: "90vh" }} className="mb-3">
        <ScrollToTop smooth />
        <div className="container-fluid nopaddingcontainer d-flex justify-content-center">
          <div className="container card section_divider extraRadius">
            <div className="row section_divider">
              <div className="col-md-6">
                <Button
                  style={{
                    borderTopLeftRadius: "5px",
                    borderBottomLeftRadius: "5px",
                    padding: "0px",
                    transform: "translateX(-10px)",
                  }}
                  onClick={handleBack}
                >
                  {" "}
                  <span className="back_btn_txt2">
                    <img
                      alt="Back"
                      style={{ transform: "rotateZ(90deg)" }}
                      srcSet="/assets/icons/Dropdown.svg"
                    />{" "}
                    Back
                  </span>
                </Button>
              </div>
              <div className="col-md-6">
                <div className="row search_input_box_NewsPage">
                  <div className="col-2 col-md-1">
                    <img
                      className="search_icon_r"
                      alt=""
                      src="/assets/icons/search_icon_light.svg"
                    />
                  </div>
                  <div className="col-10 col-md-11">
                    <input
                      onKeyUp={(e) => handleSearch(e.target.value)}
                      onChange={(e) => setSearchText(e.target.value)}
                      value={SearchText}
                      className="search_input_NewsPage "
                      placeholder="Search"
                    />
                    {SearchText !== "" ? (
                      <div
                        onClick={(e) => handleSearch("")}
                        className="searchclose"
                      >
                        <button className="btn_trans">
                          <svg
                            width="20"
                            style={{ fill: "#555" }}
                            height="20"
                            focusable="false"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                          >
                            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.58 12 5 17.58 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                          </svg>
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div
              className="row section_divider"
              style={{ marginBottom: "0px" }}
            >
              <div className="col-md-12">
                <PriceDetailBox>
                  <div>
                    <div style={{ marginBottom: "5px" }}>
                      <BankNameTypo>{stockData?.["Issuer Name"]}</BankNameTypo>
                      {(stockData?.["Industry"] ?? stockDetail?.industry) && (
                        <BankDetailTypo>
                          Sector:{" "}
                          {stockData?.["Industry"] ?? stockDetail?.industry}
                        </BankDetailTypo>
                      )}
                      {(stockData?.["SymbolTicker"] ??
                        stockDetail?.SymbolTicker) && (
                        <BankDetailTypo>
                          Symbol:{" "}
                          {stockData?.["SymbolTicker"] ??
                            stockDetail?.SymbolTicker}
                        </BankDetailTypo>
                      )}
                    </div>
                    <div>
                      {stockData?.["price_difference"] > 0 ? (
                        <>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <SRTypo>
                              {getCurrency(stockData?.["Identifier"])}{" "}
                              {stockData?.["Last"]}
                            </SRTypo>{" "}
                            <ForwardIcon
                              sx={{
                                transform: "rotate(270deg)",
                                color: "#34C759FF",
                                marginLeft: "10px",
                              }}
                            />
                          </div>
                          <ValueTypoGreen>
                            {stockData?.["price_difference"]} (
                            {stockData?.["percentage_change"]}){" "}
                            <Box component="span" sx={{ color: "#686868" }}>
                              {getDateDetail(graphFilterValue)}
                            </Box>
                          </ValueTypoGreen>
                        </>
                      ) : (
                        <>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <SRTypo>
                              {getCurrency(stockData?.["Identifier"])}{" "}
                              {stockData?.["Last"]}
                            </SRTypo>{" "}
                            <ForwardIcon
                              sx={{
                                transform: "rotate(90deg)",
                                color: "#FF3B30FF",
                                marginLeft: "10px",
                              }}
                            />
                          </div>
                          <ValueTypo>
                            {stockData?.["price_difference"]} (
                            {stockData?.["percentage_change"]}){" "}
                            <Box component="span" sx={{ color: "#686868" }}>
                              {getDateDetail(graphFilterValue)}
                            </Box>
                          </ValueTypo>
                        </>
                      )}
                      <DateTypo>
                        {graphCurrentData?.start}{" "}
                        {graphCurrentData?.end
                          ? `- ${graphCurrentData?.end}`
                          : ""}
                      </DateTypo>
                    </div>
                  </div>
                  <Stack
                    direction="column"
                    spacing={2}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    {/* {stockDetail?.image && <img height={'45px'} width='76px' style={{ marginBottom: "25px" }} alt="Back" src={stockDetail?.image.replaceAll(' ', '%20')}/>
                    } */}
                    <img
                      height={"45px"}
                      width="76px"
                      alt="Back"
                      src={`/assets/icons/${
                        getSearch("logoName") ?? "KSA"
                      }.png`}
                    />
                  </Stack>
                </PriceDetailBox>
              </div>
            </div>
            <Box sx={{ position: "relative" }}>
              <div className="row section_divider">
                <div className="col-md-12">
                  <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <Button
                      className="dateButton"
                      sx={{
                        width: "40px",
                        height: "45px",
                        fontSize: "16px",
                        color: `${
                          graphFilterValue.day == 1 ? "#000" : "#FF3B30FF"
                        }`,
                        fontWeight: 700,
                        boxShadow: "none",
                        border: `${
                          graphFilterValue.day == 1
                            ? "none"
                            : "1px solid rgb(0 0 0 / 10%)"
                        }`,
                        "&:hover": {
                          backgroundColor: "#FF3B30FF",
                          color: "#000",
                          border: "none",
                        },
                      }}
                      variant="contained"
                      color={
                        graphFilterValue.day == 1 ? "primary" : "secondary"
                      }
                      onClick={() => {
                        setGraphCurrentData({
                          start: ConvertGSTDate(
                            moment().subtract(0, "days"),
                            "DD MMM YYYY"
                          ),
                          end: null,
                        });
                        setGraphFilterValue({
                          day: 1,
                          week: 0,
                          month: 0,
                          month: 0,
                          year: 0,
                          year: 0,
                        });
                        setIsLoading(true);
                      }}
                    >
                      1d
                    </Button>
                    <Button
                      className="dateButton"
                      sx={{
                        width: "40px",
                        height: "45px",
                        fontSize: "16px",
                        color: `${
                          graphFilterValue.week == 1 ? "#000" : "#FF3B30FF"
                        }`,
                        fontWeight: 700,
                        boxShadow: "none",
                        border: `${
                          graphFilterValue.week == 1
                            ? "none"
                            : "1px solid rgb(0 0 0 / 10%)"
                        }`,
                        "&:hover": {
                          backgroundColor: "#FF3B30FF",
                          color: "#000",
                          border: "none",
                        },
                      }}
                      variant="contained"
                      color={
                        graphFilterValue.week == 1 ? "primary" : "secondary"
                      }
                      onClick={() => {
                        setGraphCurrentData({
                          start: ConvertGSTDate(
                            moment().subtract(7, "days"),
                            "DD MMM YYYY"
                          ),
                          end: ConvertGSTDate(
                            moment().subtract(0, "days"),
                            "DD MMM YYYY"
                          ),
                        });
                        setGraphFilterValue({
                          day: 0,
                          week: 1,
                          month: 0,
                          year: 0,
                        });
                        setIsLoading(true);
                      }}
                    >
                      1W
                    </Button>
                    <Button
                      className="dateButton"
                      sx={{
                        width: "40px",
                        height: "45px",
                        fontSize: "16px",
                        color: `${
                          graphFilterValue.month == 1 ? "#000" : "#FF3B30FF"
                        }`,
                        fontWeight: 700,
                        boxShadow: "none",
                        border: `${
                          graphFilterValue.month == 1
                            ? "none"
                            : "1px solid rgb(0 0 0 / 10%)"
                        }`,
                        "&:hover": {
                          backgroundColor: "#FF3B30FF",
                          color: "#000",
                          border: "none",
                        },
                      }}
                      variant="contained"
                      color={
                        graphFilterValue.month == 1 ? "primary" : "secondary"
                      }
                      onClick={() => {
                        setGraphCurrentData({
                          start: ConvertGSTDate(
                            moment().subtract(1, "months"),
                            "DD MMM YYYY"
                          ),
                          end: ConvertGSTDate(
                            moment().subtract(0, "months"),
                            "DD MMM YYYY"
                          ),
                        });
                        setGraphFilterValue({
                          day: 0,
                          week: 0,
                          month: 1,
                          year: 0,
                        });
                        setIsLoading(true);
                      }}
                    >
                      1m
                    </Button>
                    <Button
                      className="dateButton"
                      sx={{
                        width: "40px",
                        height: "45px",
                        fontSize: "16px",
                        color: `${
                          graphFilterValue.month == 6 ? "#000" : "#FF3B30FF"
                        }`,
                        fontWeight: 700,
                        boxShadow: "none",
                        border: `${
                          graphFilterValue.month == 6
                            ? "none"
                            : "1px solid rgb(0 0 0 / 10%)"
                        }`,
                        "&:hover": {
                          backgroundColor: "#FF3B30FF",
                          color: "#000",
                          border: "none",
                        },
                      }}
                      variant="contained"
                      color={
                        graphFilterValue.month == 6 ? "primary" : "secondary"
                      }
                      onClick={() => {
                        setGraphCurrentData({
                          start: ConvertGSTDate(
                            moment().subtract(6, "months"),
                            "DD MMM YYYY"
                          ),
                          end: ConvertGSTDate(
                            moment().subtract(0, "months"),
                            "DD MMM YYYY"
                          ),
                        });
                        setGraphFilterValue({
                          day: 0,
                          week: 0,
                          month: 6,
                          year: 0,
                        });
                        setIsLoading(true);
                      }}
                    >
                      6m
                    </Button>
                    <Button
                      className="dateButton"
                      sx={{
                        width: "40px",
                        height: "45px",
                        fontSize: "16px",
                        color: `${
                          graphFilterValue.year == 1 ? "#000" : "#FF3B30FF"
                        }`,
                        fontWeight: 700,
                        boxShadow: "none",
                        border: `${
                          graphFilterValue.year == 1
                            ? "none"
                            : "1px solid rgb(0 0 0 / 10%)"
                        }`,
                        "&:hover": {
                          backgroundColor: "#FF3B30FF",
                          color: "#000",
                          border: "none",
                        },
                      }}
                      variant="contained"
                      color={
                        graphFilterValue.year == 1 ? "primary" : "secondary"
                      }
                      onClick={() => {
                        setGraphCurrentData({
                          start: ConvertGSTDate(
                            moment().subtract(1, "years"),
                            "DD MMM YYYY"
                          ),
                          end: ConvertGSTDate(
                            moment().subtract(0, "years"),
                            "DD MMM YYYY"
                          ),
                        });
                        setGraphFilterValue({
                          day: 0,
                          week: 0,
                          month: 0,
                          year: 1,
                        });
                        setIsLoading(true);
                      }}
                    >
                      1y
                    </Button>
                    <Button
                      className="dateButton"
                      sx={{
                        width: "40px",
                        height: "45px",
                        fontSize: "16px",
                        color: `${
                          graphFilterValue.year == 5 ? "#000" : "#FF3B30FF"
                        }`,
                        fontWeight: 700,
                        boxShadow: "none",
                        border: `${
                          graphFilterValue.year == 5
                            ? "none"
                            : "1px solid rgb(0 0 0 / 10%)"
                        }`,
                        "&:hover": {
                          backgroundColor: "#FF3B30FF",
                          color: "#000",
                          border: "none",
                        },
                      }}
                      variant="contained"
                      color={
                        graphFilterValue.year == 5 ? "primary" : "secondary"
                      }
                      onClick={() => {
                        setGraphCurrentData({
                          start: ConvertGSTDate(
                            moment().subtract(5, "years"),
                            "DD MMM YYYY"
                          ),
                          end: ConvertGSTDate(
                            moment().subtract(0, "years"),
                            "DD MMM YYYY"
                          ),
                        });
                        setGraphFilterValue({
                          day: 0,
                          week: 0,
                          month: 0,
                          year: 5,
                        });
                        setIsLoading(true);
                      }}
                    >
                      5y
                    </Button>
                  </Stack>
                  {isLoading ? (
                    <Box
                      sx={{
                        height: "200px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  ) : (
                    <>
                      {allGraphData?.graphLineData?.length > 0 ? (
                        <HighchartsReact
                          style={{ height: "200px", marginTop: "6rem" }}
                          highcharts={Highcharts}
                          options={options}
                        />
                      ) : (
                        <Box
                          sx={{
                            height: "200px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "#5A5A5A",
                            fontWeight: 700,
                          }}
                        >
                          No Data Available
                        </Box>
                      )}
                    </>
                  )}
                </div>
              </div>
            </Box>

            <div
              className="row section_divider"
              style={{ marginBottom: "15px" }}
            >
              <div className="col-md-12">
                <HeadingTypo
                  className="blink"
                  onClick={() => navigate(`/company/${stockDetail?.id}`)}
                >
                  VIEW FINANCIAL REPORTS
                </HeadingTypo>
                <FinancialReportBox>
                  <div
                    style={{ display: "flex", justifyContent: "space-evenly" }}
                  >
                    <div style={{ width: "30%" }}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          component="span"
                          sx={{ flex: 1, color: "#ABABAB" }}
                        >
                          Open
                        </Box>
                        <Box
                          component="span"
                          sx={{ flex: 1, color: "#ABABAB" }}
                        >
                          :
                        </Box>
                        <Box component="span" sx={{ flex: 1, fontWeight: 700 }}>
                          {stockData?.["Open"]}
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          component="span"
                          sx={{ flex: 1, color: "#ABABAB" }}
                        >
                          High
                        </Box>
                        <Box
                          component="span"
                          sx={{ flex: 1, color: "#ABABAB" }}
                        >
                          :
                        </Box>
                        <Box component="span" sx={{ flex: 1, fontWeight: 700 }}>
                          {stockData?.["High"]}
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          component="span"
                          sx={{ flex: 1, color: "#ABABAB" }}
                        >
                          Low
                        </Box>
                        <Box
                          component="span"
                          sx={{ flex: 1, color: "#ABABAB" }}
                        >
                          :
                        </Box>
                        <Box component="span" sx={{ flex: 1, fontWeight: 700 }}>
                          {stockData?.["Low"]}
                        </Box>
                      </Box>
                    </div>
                    <div style={{ borderLeft: "1px solid #D9D9D9" }}></div>
                    <div style={{ width: "30%" }}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          component="span"
                          sx={{ flex: 1, color: "#ABABAB" }}
                        >
                          Vol
                        </Box>
                        <Box
                          component="span"
                          sx={{ flex: 1, color: "#ABABAB" }}
                        >
                          :
                        </Box>
                        <Box component="span" sx={{ flex: 1, fontWeight: 700 }}>
                          {Intl.NumberFormat("en-US", {
                            notation: "compact",
                            maximumFractionDigits: 1,
                          }).format(stockData?.["Volume"] ?? 0)}
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          component="span"
                          sx={{ flex: 1, color: "#ABABAB" }}
                        >
                          Last
                        </Box>
                        <Box
                          component="span"
                          sx={{ flex: 1, color: "#ABABAB" }}
                        >
                          :
                        </Box>
                        <Box component="span" sx={{ flex: 1, fontWeight: 700 }}>
                          {stockData?.["Last"]}
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          component="span"
                          sx={{ flex: 1, color: "#ABABAB" }}
                        >
                          Close
                        </Box>
                        <Box
                          component="span"
                          sx={{ flex: 1, color: "#ABABAB" }}
                        >
                          :
                        </Box>
                        <Box component="span" sx={{ flex: 1, fontWeight: 700 }}>
                          {stockData?.["Universal Close Price"]}
                        </Box>
                      </Box>
                    </div>
                  </div>
                </FinancialReportBox>
              </div>
            </div>

            <div className="row section_divider mb-3">
              <div className="col-md-12">
                <HeadingTypo sx={{ textAlign: "left" }}>NEWS</HeadingTypo>
                <Grid container spacing={2}>
                  <>
                    {News?.length > 0 ? (
                      News?.map(function (value, index, array) {
                        return (
                          <Newsitem
                            size={3}
                            title={value.source}
                            description={value.title}
                            created={value.created}
                            image={value.image_url}
                            url_link={value.link}
                            date={value.created_date}
                            key={index}
                          />
                        );
                      })
                    ) : (
                      <Box sx={{ pl: 2, pt: 1 }}> No News Available.</Box>
                    )}
                  </>
                </Grid>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default StockPriceDetail;
