import React, { useState } from "react";
import ScrollToTop from "react-scroll-to-top";
import {
  Button,
  Grid,
  Chip,
  Avatar,
  Typography,
  TableSortLabel,
  Box,
  Stack,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getFlag, getSearch, setSearch } from "../API/LocalStore";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { setheadermenuData } from "../reducers/HeaderMenuReducer";
import { useEffect } from "react";
import {
  allcompaniesApi,
  getCountries,
  getFinancialNews,
  getFinancialNewsPost,
} from "../API/Userapis";
import axios from "axios";
import TableComponent from "../components/table";
import { useDispatch } from "react-redux";
import { setStockData } from "../reducers/StockDataReducer";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#EFF0F4",
    color: "#000000",
    fontWeight: 600,
    fontSize: "12px",
    width: "30%",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 10,
    width: "30%",
  },
}));

const headerCell = [
  {
    id: "Identifier",
    width: "7%",
    label: "TICKER",
    align: "left",
  },
  {
    id: "Issuer Name",
    width: 220,
    label: "COMPANY NAME",
    align: "left",
  },
  {
    id: "Industry",
    width: 120,
    label: "INDUSTRY",
    class: "INDUSTRY",
    align: "left",
  },
  {
    id: "Last",
    width: 50,
    label: "LAST",
    align: "right",
  },
  {
    id: "Open",
    width: 50,
    label: "OPEN",
    align: "right",
  },
  {
    id: "High",
    width: 50,
    label: "HIGH",
    align: "right",
  },
  {
    id: "Low",
    width: 50,
    label: "LOW",
    align: "right",
  },
  {
    id: "Universal Close Price",
    width: 50,
    label: "CLOSE",
    align: "right",
  },
  {
    id: "price_difference",
    width: 50,
    label: "CHANGE",
    align: "right",
  },
  {
    id: "percentage_change",
    width: 100,
    label: "CHANGE (%)",
    align: "right",
  },
  {
    id: "Volume",
    width: 40,
    numeric: true,
    label: "VOLUME",
    align: "right",
  },
  {
    id: "Trade Date",
    width: 130,
    label: "DATE",
    align: "center",
  },
  {
    id: "Exchange Description",
    width: "13%",
    label: "EXCHANGE",
    align: "left",
  },
];

// const headerCell = ['TICKER COMPANY NAME', 'INDUSTRY', 'LAST', 'OPEN', 'HIGH', 'LOW', 'CLOSE', 'CHANGE', 'CHANGE (%)', 'VOLUME', 'DATE', 'EXCHANGE']

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StockListPrice = () => {
  const navigate = useNavigate();
  const [selectedCountry, setselectedCountry] = useState(
    getSearch("selectedCountry") ?? "KSA"
  );
  const [selectedChildCountry, setselectedChildCountry] = useState(
    getSearch("selectedChildCountry") ?? ""
  );
  const [SearchText, setSearchText] = useState("");
  const [Countries, setCountries] = useState([]);
  const [data, setData] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [cacheData, setCacheData] = React.useState({});
  const dispatch = useDispatch();

  const getRowData = (dataRows, type) => {
    if (dataRows.length > 0) {
      if (type == "All") {
        return dataRows;
      } else if (type == "KSA") {
        return dataRows.filter((row) => row.Identifier.includes(".SE"));
      } else if (type == "NOMU") {
        return dataRows.filter((row) =>
          row.Exchange == "NOMU" ? row.Exchange : ""
        );
      } else if (type == "TASI") {
        return dataRows.filter((row) =>
          row.Exchange == "TASI" ? row.Exchange : ""
        );
      } else if (type == "UAE") {
        return dataRows.filter(
          (row) =>
            row.Identifier.includes(".AD") ||
            row.Identifier.includes(".DU") ||
            row.Identifier.includes(".DI")
        );
      } else if (type == "DFM") {
        return dataRows.filter((row) =>
          row.Exchange == "DFM" ? row.Exchange : ""
        );
      } else if (type == "ADX") {
        return dataRows.filter((row) =>
          row.Exchange == "ADX" ? row.Exchange : ""
        );
      } else if (type == "Oman") {
        return dataRows.filter((row) => row.Identifier.includes(".OM"));
      } else if (type == "UNDER_MONITORING MARKET") {
        return dataRows.filter((row) =>
          row.Exchange == "UNDER_MONITORING MARKET" ? row.Exchange : ""
        );
      } else if (type == "REGULAR MARKET") {
        return dataRows.filter((row) =>
          row.Exchange == "REGULAR MARKET" ? row.Exchange : ""
        );
      } else if (type == "PARALLEL MARKET") {
        return dataRows.filter((row) =>
          row.Exchange == "PARALLEL MARKET" ? row.Exchange : ""
        );
      } else if (type == "Kuwait") {
        return dataRows.filter((row) => row.Identifier.includes(".KW"));
      } else if (type == "MAIN MARKET") {
        return dataRows.filter((row) =>
          row.Exchange == "MAIN MARKET" ? row.Exchange : ""
        );
      } else if (type == "PREMIER MARKET") {
        return dataRows.filter((row) =>
          row.Exchange == "PREMIER MARKET" ? row.Exchange : ""
        );
      } else if (type == "Bahrain") {
        return dataRows.filter((row) => row.Identifier.includes(".BH"));
      } else if (type == "BAHRAIN BOURSE") {
        return dataRows.filter((row) =>
          row.Exchange == "BAHRAIN BOURSE" ? row.Exchange : ""
        );
      } else if (type == "Qatar") {
        return dataRows.filter((row) => row.Identifier.includes(".QA"));
      } else if (type == "QATAR STOCK EXCHANGE") {
        return dataRows.filter((row) =>
          row.Exchange == "QATAR STOCK EXCHANGE" ? row.Exchange : ""
        );
      }
    }
  };

  const getTableData = () => {
    if (getSearch("listData")) {
      setData(JSON.parse(getSearch("listData")) ?? []);
      setRows(
        getRowData(
          JSON.parse(getSearch("listData")),
          selectedChildCountry || selectedCountry
        )
      );
      setCacheData({
        All: getRowData(JSON.parse(getSearch("listData")), "All"),
        KSA: getRowData(JSON.parse(getSearch("listData")), "KSA"),
        NOMU: getRowData(JSON.parse(getSearch("listData")), "NOMU"),
        TASI: getRowData(JSON.parse(getSearch("listData")), "TASI"),
        UAE: getRowData(JSON.parse(getSearch("listData")), "UAE"),
        DFM: getRowData(JSON.parse(getSearch("listData")), "DFM"),
        ADX: getRowData(JSON.parse(getSearch("listData")), "ADX"),
        Oman: getRowData(JSON.parse(getSearch("listData")), "Oman"),
        "UNDER_MONITORING MARKET": getRowData(
          JSON.parse(getSearch("listData")),
          "UNDER_MONITORING MARKET"
        ),
        "REGULAR MARKET": getRowData(
          JSON.parse(getSearch("listData")),
          "REGULAR MARKET"
        ),
        "PARALLEL MARKET": getRowData(
          JSON.parse(getSearch("listData")),
          "PARALLEL MARKET"
        ),
        Kuwait: getRowData(JSON.parse(getSearch("listData")), "Kuwait"),
        "MAIN MARKET": getRowData(
          JSON.parse(getSearch("listData")),
          "MAIN MARKET"
        ),
        "PREMIER MARKET": getRowData(
          JSON.parse(getSearch("listData")),
          "PREMIER MARKET"
        ),
        Bahrain: getRowData(JSON.parse(getSearch("listData")), "Bahrain"),
        "BAHRAIN BOURSE": getRowData(
          JSON.parse(getSearch("listData")),
          "BAHRAIN BOURSE"
        ),
        Qatar: getRowData(JSON.parse(getSearch("listData")), "Qatar"),
        "QATAR STOCK EXCHANGE": getRowData(
          JSON.parse(getSearch("listData")),
          "QATAR STOCK EXCHANGE"
        ),
      });
      setIsLoading(false);
    } else {
      axios
        .get("https://refinitive.ashom.app/stock/getall", {})
        .then((response) => {
          console.log(response?.data);
          setData(response?.data ?? []);
          setRows(
            response?.data?.filter((row) => row.Identifier.includes(".SE"))
          );
          setSearch("listData", JSON.stringify(response?.data));
          setSearch("cacheListData", JSON.stringify(response?.data));
          setCacheData({
            All: getRowData(response?.data, "All"),
            KSA: getRowData(response?.data, "KSA"),
            NOMU: getRowData(response?.data, "NOMU"),
            TASI: getRowData(response?.data, "TASI"),
            UAE: getRowData(response?.data, "UAE"),
            DFM: getRowData(response?.data, "DFM"),
            ADX: getRowData(response?.data, "ADX"),
            Oman: getRowData(response?.data, "Oman"),
            "UNDER_MONITORING MARKET": getRowData(
              response?.data,
              "UNDER_MONITORING MARKET"
            ),
            "REGULAR MARKET": getRowData(response?.data, "REGULAR MARKET"),
            "PARALLEL MARKET": getRowData(response?.data, "PARALLEL MARKET"),
            Kuwait: getRowData(response?.data, "Kuwait"),
            "MAIN MARKET": getRowData(response?.data, "MAIN MARKET"),
            "PREMIER MARKET": getRowData(response?.data, "PREMIER MARKET"),
            Bahrain: getRowData(response?.data, "Bahrain"),
            "BAHRAIN BOURSE": getRowData(response?.data, "BAHRAIN BOURSE"),
            Qatar: getRowData(response?.data, "Qatar"),
            "QATAR STOCK EXCHANGE": getRowData(
              response?.data,
              "QATAR STOCK EXCHANGE"
            ),
          });
          setIsLoading(false);
        })
        .catch((error) => {
          console.log("responsemapDataerrr", error);
          if (getSearch("cacheListData")) {
            setData(JSON.parse(getSearch("cacheListData")) ?? []);
            setRows(
              JSON.parse(getSearch("cacheListData"))?.filter((row) =>
                row.Identifier.includes(".SE")
              )
            );
            setCacheData({
              All: getRowData(JSON.parse(getSearch("cacheListData")), "All"),
              KSA: getRowData(JSON.parse(getSearch("cacheListData")), "KSA"),
              NOMU: getRowData(JSON.parse(getSearch("cacheListData")), "NOMU"),
              TASI: getRowData(JSON.parse(getSearch("cacheListData")), "TASI"),
              UAE: getRowData(JSON.parse(getSearch("cacheListData")), "UAE"),
              DFM: getRowData(JSON.parse(getSearch("cacheListData")), "DFM"),
              ADX: getRowData(JSON.parse(getSearch("cacheListData")), "ADX"),
              Oman: getRowData(JSON.parse(getSearch("cacheListData")), "Oman"),
              "UNDER_MONITORING MARKET": getRowData(
                JSON.parse(getSearch("cacheListData")),
                "UNDER_MONITORING MARKET"
              ),
              "REGULAR MARKET": getRowData(
                JSON.parse(getSearch("cacheListData")),
                "REGULAR MARKET"
              ),
              "PARALLEL MARKET": getRowData(
                JSON.parse(getSearch("cacheListData")),
                "PARALLEL MARKET"
              ),
              Kuwait: getRowData(
                JSON.parse(getSearch("cacheListData")),
                "Kuwait"
              ),
              "MAIN MARKET": getRowData(
                JSON.parse(getSearch("cacheListData")),
                "MAIN MARKET"
              ),
              "PREMIER MARKET": getRowData(
                JSON.parse(getSearch("cacheListData")),
                "PREMIER MARKET"
              ),
              Bahrain: getRowData(
                JSON.parse(getSearch("cacheListData")),
                "Bahrain"
              ),
              "BAHRAIN BOURSE": getRowData(
                JSON.parse(getSearch("cacheListData")),
                "BAHRAIN BOURSE"
              ),
              Qatar: getRowData(
                JSON.parse(getSearch("cacheListData")),
                "Qatar"
              ),
              "QATAR STOCK EXCHANGE": getRowData(
                JSON.parse(getSearch("cacheListData")),
                "QATAR STOCK EXCHANGE"
              ),
            });
          }
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    getTableData();
    dispatch(
      setheadermenuData({
        currentpath: "/stock-price",
        headerfootershow: true,
      })
    );
  }, []);

  const changeCountry = (country) => {
    setSearch("selectedCountry", country);
    setSearch("selectedChildCountry", "");
    setselectedChildCountry("");
    setselectedCountry(country);
    if (selectedCountry == "KSA") {
      document.getElementById("tasi").classList.remove("ksa-markets");
      document.getElementById("nomu").classList.remove("ksa-markets");
    } else if (selectedCountry == "UAE") {
      document.getElementById("dfm").classList.remove("uae-markets");
      document.getElementById("adx").classList.remove("uae-markets");
    } else if (selectedCountry == "Kuwait") {
      document.getElementById("main-mkt").classList.remove("kuwait-markets");
      document.getElementById("premier-mkt").classList.remove("kuwait-markets");
    } else if (selectedCountry == "Bahrain") {
      document.getElementById("bahrain").classList.remove("bahrain-markets");
    } else if (selectedCountry == "Qatar") {
      document.getElementById("qatar").classList.remove("qatar-markets");
    } else if (selectedCountry == "Oman") {
      document
        .getElementById("under-monitoring")
        .classList.remove("oman-markets");
      document.getElementById("regular-mkt").classList.remove("oman-markets");
      document.getElementById("parallel-mkt").classList.remove("oman-markets");
    }
  };

  useEffect(() => {
    if (getSearch("countries")) {
      setCountries(JSON.parse(getSearch("countries")));
    } else {
      getCountries().then((meta) => {
        setSearch("countries", JSON.stringify(meta));
        setCountries(meta);
        // if (getSearch('newssearch')) {
        //   let searchtext = getSearch('newssearch');
        //   let selectedCountry = getSearch('newssearchcountry') ? getSearch('newssearchcountry') : "";
        //   setselectedCountry(selectedCountry);
        //   setSearchText(searchtext);
        //   getFinancialNewsPost(0, selectedCountry, "", searchtext).then(meta => {
        //   });
        // }
      });
    }
  }, []);

  const handleBack = () => {
    navigate(-1);
  };

  function fetchCompanieDetail() {
    allcompaniesApi().then((meta) => {
      setSearch("stockDetail", JSON.stringify(meta));
      // setStockDetail(meta?.find(item => stockData?.["RIC"]?.split(".")?.[1] == 'SE' ? item?.Reference_No.startsWith(stockData?.["RIC"]?.split(".")?.[0]) : item?.SymbolTicker.startsWith(stockData?.["RIC"]?.split(".")?.[0])) ?? {})
    });
  }

  useEffect(() => {
    fetchCompanieDetail();
  }, []);

  const handleChange = (row, ticker, name) => {
    setSearch("stockData", JSON.stringify(row));
    setSearch("ticker", row?.Identifier);
    setSearch("logoName", selectedCountry);
    setSearch(
      "stokeTicker",
      JSON.parse(getSearch("stockDetail"))?.find(
        (item) => row?.Identifier == item?.["RIC"]
      )?.["SymbolTicker"]
    );
    dispatch(setStockData(row));
    navigate(`/stock-price/detail`);
  };

  const getFilter = (row, Searchvalue) => {
    if (
      row?.Identifier &&
      row?.Identifier.toString()
        .toLowerCase()
        .indexOf(Searchvalue.toLowerCase()) > -1
    ) {
      return true;
    }
    if (
      row?.["Issuer Name"] &&
      row?.["Issuer Name"]
        .toString()
        .toLowerCase()
        .indexOf(Searchvalue.toLowerCase()) > -1
    ) {
      return true;
    }
    if (
      row?.SymbolTicker &&
      row?.SymbolTicker.toString()
        .toLowerCase()
        .indexOf(Searchvalue.toLowerCase()) > -1
    ) {
      return true;
    }
    if (
      row?.Industry &&
      row?.Industry.toString()
        .toLowerCase()
        .indexOf(Searchvalue.toLowerCase()) > -1
    ) {
      return true;
    }
    return false;
  };

  const handleSearch = (Searchvalue) => {
    setSearchText(Searchvalue);
    if (
      (selectedCountry == "All" ||
        selectedCountry == "UAE" ||
        selectedCountry == "KSA" ||
        selectedCountry == "Kuwait" ||
        selectedCountry == "Bahrain" ||
        selectedCountry == "Qatar" ||
        selectedCountry == "Oman") &&
      selectedChildCountry != ""
    ) {
      setRows(
        cacheData?.[selectedChildCountry]?.filter((row) =>
          getFilter(row, Searchvalue)
        )
      );
    } else {
      setRows(
        cacheData?.[selectedCountry]?.filter((row) =>
          getFilter(row, Searchvalue)
        )
      );
    }
  };

  useEffect(() => {
    if (data.length > 0) {
      if (
        (selectedCountry == "UAE" ||
          selectedCountry == "All" ||
          selectedCountry == "KSA" ||
          selectedCountry == "Kuwait" ||
          selectedCountry == "Bahrain" ||
          selectedCountry == "Qatar" ||
          selectedCountry == "Oman") &&
        selectedChildCountry != ""
      ) {
        // console.log(selectedChildCountry);
        setRows(cacheData?.[selectedChildCountry] ?? []);
        setSearchText("");
        setIsLoading(false);
      } else {
        setRows(cacheData?.[selectedCountry] ?? []);
        setSearchText("");
        setIsLoading(false);
      }
    }
  }, [selectedCountry, selectedChildCountry]);

  return (
    <div style={{ minHeight: "90vh" }} className="mb-3">
      <ScrollToTop smooth />
      <div className="container-fluid nopaddingcontainer">
        <div
          className="card section_divider"
          horizontal="true"
          style={{
            marginLeft: "10%",
            marginRight: "10%",
            paddingRight: "1%",
            paddingLeft: "1%",
          }}
        >
          <div className="row section_divider">
            <div className="col-md-6">
              <Button
                style={{
                  borderTopLeftRadius: "5px",
                  borderBottomLeftRadius: "5px",
                  padding: "0px",
                  transform: "translateX(-10px)",
                }}
                onClick={handleBack}
              >
                {" "}
                <span className="back_btn_txt2">
                  <img
                    alt="Back"
                    style={{ transform: "rotateZ(90deg)" }}
                    srcSet="/assets/icons/Dropdown.svg"
                  />{" "}
                  Back
                </span>
              </Button>
            </div>
            <div className="col-md-6">
              <div
                className={
                  Object.keys(cacheData).length > 0
                    ? "row search_input_box_NewsPage"
                    : "row search_input_box_NewsPage div-disabled"
                }
              >
                <div className="col-1" style={{ paddingRight: "0px" }}>
                  <img
                    className="search_icon_r"
                    alt=""
                    srcSet="/assets/icons/search_icon_light.svg"
                  />
                </div>
                <div className="col-11 ">
                  <input
                    onChange={(e) => handleSearch(e.target.value)}
                    value={SearchText}
                    className="search_input_NewsPage "
                    placeholder="Search"
                  />
                  {SearchText !== "" ? (
                    <div
                      onClick={(e) => handleSearch("")}
                      className="searchclose"
                    >
                      <button className="btn_trans">
                        <svg
                          width="20"
                          style={{ fill: "#555" }}
                          height="20"
                          focusable="false"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                        >
                          <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.58 12 5 17.58 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                        </svg>
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="row section_divider" horizontal="true">
            <div className="col-md-12" horizontal="true">
              <Box className="flags_div d-flex" sx={{ gap: "5px" }}>
                <Chip
                  onClick={() => changeCountry("All")}
                  avatar={<Avatar alt="A" src="/assets/icons/globe.png" />}
                  label="All"
                  variant={selectedCountry == "All" ? "" : "outlined"}
                />
                {Countries.length == 0
                  ? ""
                  : Countries.map(function (value, index, array) {
                      return (
                        <Chip
                          sx={{
                            width: "92px",
                            minWidth: "92px",
                            maxWidth: "92px",
                            display: "flex",
                            justifyContent: "start ",
                          }}
                          key={index}
                          onClick={() => {
                            if (selectedCountry != value.country) {
                              setIsLoading(true);
                            }
                            changeCountry(value.country);
                          }}
                          avatar={
                            <Avatar
                              alt={value.country[0]}
                              src={`assets/icons/${value.country}.png`}
                            />
                          }
                          label={value.country}
                          variant={
                            selectedCountry == value.country ? "" : "outlined"
                          }
                        />
                      );
                    })}
              </Box>
              {selectedCountry == "KSA" && (
                <Stack flexDirection="row" gap="4px" sx={{ mt: 1, ml: "62px" }}>
                  <Chip
                    id="tasi"
                    sx={{
                      width: "45px",
                      span: {
                        p: 0,
                      },
                    }}
                    label="TASI"
                    onClick={() => {
                      if (selectedChildCountry != "TASI") setIsLoading(true);
                      setSearch("selectedChildCountry", "TASI");
                      setselectedChildCountry("TASI");
                      document
                        .getElementById("tasi")
                        .classList.add("ksa-markets");
                      document
                        .getElementById("nomu")
                        .classList.remove("ksa-markets");
                    }}
                    // variant={selectedChildCountry == "TASI" ? "" : "outlined"}
                  />
                  <Chip
                    id="nomu"
                    sx={{
                      width: "55px",
                      span: {
                        p: 0,
                      },
                    }}
                    label="NOMU"
                    onClick={() => {
                      if (selectedChildCountry != "NOMU") setIsLoading(true);
                      setSearch("selectedChildCountry", "NOMU");
                      setselectedChildCountry("NOMU");
                      document
                        .getElementById("nomu")
                        .classList.add("ksa-markets");
                      document
                        .getElementById("tasi")
                        .classList.remove("ksa-markets");
                    }}
                    // variant={selectedChildCountry == "NOMU" ? "" : "outlined"}
                  />
                </Stack>
              )}
              {selectedCountry == "UAE" && (
                <Stack
                  flexDirection="row"
                  gap="4px"
                  sx={{ mt: 1, ml: "164px" }}
                >
                  <Chip
                    id="dfm"
                    sx={{
                      width: "45px",
                      span: {
                        p: 0,
                      },
                    }}
                    label="DFM"
                    onClick={() => {
                      if (selectedChildCountry != "DFM") setIsLoading(true);
                      setSearch("selectedChildCountry", "DFM");
                      setselectedChildCountry("DFM");
                      document
                        .getElementById("dfm")
                        .classList.add("uae-markets");
                      document
                        .getElementById("adx")
                        .classList.remove("uae-markets");
                    }}
                    // variant={selectedChildCountry == "DFM" ? "" : "outlined"}
                  />
                  <Chip
                    id="adx"
                    sx={{
                      width: "45px",
                      span: {
                        p: 0,
                      },
                    }}
                    label="ADX"
                    onClick={() => {
                      if (selectedChildCountry != "ADX") setIsLoading(true);
                      setSearch("selectedChildCountry", "ADX");
                      setselectedChildCountry("ADX");
                      document
                        .getElementById("adx")
                        .classList.add("uae-markets");
                      document
                        .getElementById("dfm")
                        .classList.remove("uae-markets");
                    }}
                    // variant={selectedChildCountry == "ADX" ? "" : "outlined"}
                  />
                </Stack>
              )}
              {selectedCountry == "Kuwait" && (
                <Stack
                  flexDirection="row"
                  gap="4px"
                  sx={{ mt: 1, ml: "200px" }}
                >
                  <Chip
                    id="main-mkt"
                    sx={{
                      width: "110px",
                      span: {
                        p: 0,
                      },
                    }}
                    label="MAIN MARKET"
                    onClick={() => {
                      if (selectedChildCountry != "MAIN MARKET")
                        setIsLoading(true);
                      setSearch("selectedChildCountry", "MAIN MARKET");
                      setselectedChildCountry("MAIN MARKET");
                      document
                        .getElementById("main-mkt")
                        .classList.add("kuwait-markets");
                      document
                        .getElementById("premier-mkt")
                        .classList.remove("kuwait-markets");
                    }}
                    // variant={
                    //   selectedChildCountry == "MAIN MARKET" ? "" : "outlined"
                    // }
                  />
                  <Chip
                    id="premier-mkt"
                    sx={{
                      width: "130px",
                      span: {
                        p: 0,
                      },
                    }}
                    label="PREMIER MARKET"
                    onClick={() => {
                      if (selectedChildCountry != "PREMIER MARKET")
                        setIsLoading(true);
                      setSearch("selectedChildCountry", "PREMIER MARKET");
                      setselectedChildCountry("PREMIER MARKET");
                      document
                        .getElementById("premier-mkt")
                        .classList.add("kuwait-markets");
                      document
                        .getElementById("main-mkt")
                        .classList.remove("kuwait-markets");
                    }}
                    // variant={
                    //   selectedChildCountry == "PREMIER MARKET" ? "" : "outlined"
                    // }
                  />
                </Stack>
              )}
              {selectedCountry == "Bahrain" && (
                <Stack
                  flexDirection="row"
                  gap="4px"
                  sx={{ mt: 1, ml: "340px" }}
                >
                  <Chip
                    id="bahrain"
                    sx={{
                      width: "130px",
                      span: {
                        p: 0,
                      },
                    }}
                    label="BAHRAIN BOURSE"
                    onClick={() => {
                      if (selectedChildCountry != "BAHRAIN BOURSE")
                        setIsLoading(true);
                      setSearch("selectedChildCountry", "BAHRAIN BOURSE");
                      setselectedChildCountry("BAHRAIN BOURSE");
                      document
                        .getElementById("bahrain")
                        .classList.add("bahrain-markets");
                    }}
                    // variant={
                    //   selectedChildCountry == "BAHRAIN BOURSE" ? "" : "outlined"
                    // }
                  />
                </Stack>
              )}
              {selectedCountry == "Qatar" && (
                <Stack
                  flexDirection="row"
                  gap="4px"
                  sx={{ mt: 1, ml: "406px" }}
                >
                  <Chip
                    id="qatar"
                    sx={{
                      width: "180px",
                      span: {
                        p: 0,
                      },
                    }}
                    label="QATAR STOCK EXCHANGE"
                    onClick={() => {
                      if (selectedChildCountry != "QATAR STOCK EXCHANGE")
                        setIsLoading(true);
                      setSearch("selectedChildCountry", "QATAR STOCK EXCHANGE");
                      setselectedChildCountry("QATAR STOCK EXCHANGE");
                      document
                        .getElementById("qatar")
                        .classList.add("qatar-markets");
                    }}
                    // variant={
                    //   selectedChildCountry == "QATAR STOCK EXCHANGE"
                    //     ? ""
                    //     : "outlined"
                    // }
                  />
                </Stack>
              )}
              {selectedCountry == "Oman" && (
                <Stack
                  flexDirection="row"
                  gap="4px"
                  sx={{ mt: 1, ml: "320px" }}
                >
                  <Chip
                    id="under-monitoring"
                    sx={{
                      width: "210px",
                      span: {
                        p: 0,
                      },
                    }}
                    label="UNDER_MONITORING MARKET"
                    onClick={() => {
                      if (selectedChildCountry != "UNDER_MONITORING MARKET")
                        setIsLoading(true);
                      setSearch(
                        "selectedChildCountry",
                        "UNDER_MONITORING MARKET"
                      );
                      setselectedChildCountry("UNDER_MONITORING MARKET");
                      document
                        .getElementById("under-monitoring")
                        .classList.add("oman-markets");
                      document
                        .getElementById("regular-mkt")
                        .classList.remove("oman-markets");
                      document
                        .getElementById("parallel-mkt")
                        .classList.remove("oman-markets");
                    }}
                    // variant={
                    //   selectedChildCountry == "UNDER_MONITORING MARKET"
                    //     ? ""
                    //     : "outlined"
                    // }
                  />
                  <Chip
                    id="regular-mkt"
                    sx={{
                      width: "130px",
                      span: {
                        p: 0,
                      },
                    }}
                    label="REGULAR MARKET"
                    onClick={() => {
                      if (selectedChildCountry != "REGULAR MARKET")
                        setIsLoading(true);
                      setSearch("selectedChildCountry", "REGULAR MARKET");
                      setselectedChildCountry("REGULAR MARKET");
                      document
                        .getElementById("regular-mkt")
                        .classList.add("oman-markets");
                      document
                        .getElementById("under-monitoring")
                        .classList.remove("oman-markets");
                      document
                        .getElementById("parallel-mkt")
                        .classList.remove("oman-markets");
                    }}
                    // variant={
                    //   selectedChildCountry == "REGULAR MARKET" ? "" : "outlined"
                    // }
                  />
                  <Chip
                    id="parallel-mkt"
                    sx={{
                      width: "130px",
                      span: {
                        p: 0,
                      },
                    }}
                    label="PARALLEL MARKET"
                    onClick={() => {
                      if (selectedChildCountry != "PARALLEL MARKET")
                        setIsLoading(true);
                      setSearch("selectedChildCountry", "PARALLEL MARKET");
                      setselectedChildCountry("PARALLEL MARKET");
                      document
                        .getElementById("parallel-mkt")
                        .classList.add("oman-markets");
                      document
                        .getElementById("under-monitoring")
                        .classList.remove("oman-markets");
                      document
                        .getElementById("regular-mkt")
                        .classList.remove("oman-markets");
                    }}
                    // variant={
                    //   selectedChildCountry == "PARALLEL MARKET"
                    //     ? ""
                    //     : "outlined"
                    // }
                  />
                </Stack>
              )}
            </div>
          </div>

          <div className="row section_divider">
            <div className="col-md-12">
              {/* <StockPriceTable /> */}
              <TableComponent
                rows={rows}
                isLoading={isLoading}
                columns={headerCell}
                defaultOrderBy="Issuer Name"
                renderRow={(row, index) => {
                  return (
                    <StyledTableRow hover tabIndex={-1} key={row.name}>
                      <TableCell
                        align="start"
                        sx={{
                          textAlign: "left",
                          fontSize: "12px",
                          px: "2px",
                          py: 1,
                        }}
                      >
                        {row?.Identifier}
                      </TableCell>
                      <TableCell
                        align="start"
                        sx={{
                          textAlign: "left",
                          fontSize: "12px",
                          px: "2px",
                          py: 1,
                        }}
                      >
                        <Box
                          onClick={() =>
                            handleChange(
                              row,
                              row?.SymbolTicker,
                              row?.["Reference Company"]
                            )
                          }
                          component="span"
                          sx={{ color: "#655DFE", cursor: "pointer" }}
                        >
                          {row?.["Issuer Name"]}
                        </Box>
                      </TableCell>
                      <TableCell
                        align="start"
                        sx={{
                          textAlign: "left",
                          fontSize: "12px",
                          textTransform: "uppercase",
                          px: "2px",
                          py: 1,
                        }}
                      >
                        {row?.Industry}
                      </TableCell>
                      <TableCell
                        align="end"
                        sx={{
                          textAlign: "right",
                          fontSize: "12px",
                          px: "2px",
                          py: 1,
                        }}
                      >
                        {row?.Last}
                      </TableCell>
                      <TableCell
                        align="end"
                        sx={{
                          textAlign: "right",
                          fontSize: "12px",
                          px: "2px",
                          py: 1,
                        }}
                      >
                        {row?.Open}
                      </TableCell>
                      <TableCell
                        align="end"
                        sx={{
                          textAlign: "right",
                          fontSize: "12px",
                          px: "2px",
                          py: 1,
                        }}
                      >
                        {row?.High}
                      </TableCell>
                      <TableCell
                        align="end"
                        sx={{
                          textAlign: "right",
                          fontSize: "12px",
                          px: "2px",
                          py: 1,
                        }}
                      >
                        {row?.Low}
                      </TableCell>
                      <TableCell
                        align="end"
                        sx={{
                          textAlign: "right",
                          fontSize: "12px",
                          px: "2px",
                          py: 1,
                        }}
                      >
                        {row?.["Universal Close Price"]}
                      </TableCell>
                      <TableCell
                        align="end"
                        sx={{
                          textAlign: "right",
                          fontSize: "12px",
                          px: "2px",
                          py: 1,
                        }}
                      >
                        {row?.price_difference > 0 ? (
                          <Box component="span" sx={{ color: "#34C759FF" }}>
                            {row?.price_difference}
                          </Box>
                        ) : row?.price_difference == 0 ? (
                          <Box component="span" sx={{ color: "#000" }}>
                            {row?.price_difference}
                          </Box>
                        ) : (
                          <Box component="span" sx={{ color: "#FF3B30FF" }}>
                            {row?.price_difference}
                          </Box>
                        )}
                      </TableCell>
                      <TableCell
                        align="end"
                        sx={{
                          textAlign: "right",
                          fontSize: "12px",
                          px: "2px",
                          py: 1,
                        }}
                      >
                        {row?.percentage_change > 0 ? (
                          <Box component="span" sx={{ color: "#34C759FF" }}>
                            {row?.percentage_change}
                          </Box>
                        ) : row?.percentage_change == 0 ? (
                          <Box component="span" sx={{ color: "#000" }}>
                            {row?.percentage_change}
                          </Box>
                        ) : (
                          <Box component="span" sx={{ color: "#FF3B30FF" }}>
                            {row?.percentage_change}
                          </Box>
                        )}
                      </TableCell>
                      <TableCell
                        align="end"
                        sx={{
                          textAlign: "right",
                          fontSize: "12px",
                          px: "2px",
                          py: 1,
                        }}
                      >
                        {Intl.NumberFormat("en-US", {
                          notation: "compact",
                          maximumFractionDigits: 1,
                        }).format(row?.Volume ?? 0)}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          textAlign: "center",
                          fontSize: "12px",
                          px: "2px",
                          py: 1,
                        }}
                      >
                        {row?.["Trade Date"]}
                      </TableCell>
                      <TableCell
                        align="start"
                        sx={{
                          textAlign: "left",
                          fontSize: "12px",
                          px: "2px",
                          py: 1,
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          width: "150px",
                          maxWidth: "150px",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {row?.["Exchange Description"]}
                      </TableCell>
                    </StyledTableRow>
                  );
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StockListPrice;
