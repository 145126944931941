import React, { useEffect, useState } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setheadermenuData } from "../reducers/HeaderMenuReducer";
import CompanySearch from "../components/CompanySearch";
import { showsubscriptionmodal } from "../reducers/SubscriptionModalReducer";
import { GAEvenet } from "../API/GoogleAnalytics";
import SearchBox from "../components/SearchBox";
import { removeSearch } from "../API/LocalStore";
import { Button } from "@mui/material";
import { Modal } from "react-bootstrap";
import closeIcon from "../images/close-icon.png";
import ashomAnalytics from "../images/ashom-analytics.png";
import xlsxDownload from "../images/xlsx_download.png";

const Companiespage = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let params = useParams();
  let company_id = params.id;
  const navigate = useNavigate();
  const GoToAshomAnalytics = (e) => {
    e.preventDefault();
    handleShow();
    // navigate("/ashom-plus");
  };
  const GoToXLSXDownload = (e) => {
    e.preventDefault();
    navigate("/ashom-plus-financials");
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      setheadermenuData({ currentpath: "/ashom-plus", headerfootershow: true })
    );
    GAEvenet();
    let isFirstTimeUser = sessionStorage.getItem("firsttimeuser");
    if (isFirstTimeUser) {
      sessionStorage.removeItem("firsttimeuser");
      dispatch(showsubscriptionmodal({ value: true }));
    }
  }, []);

  return (
    <div className="d-flex justify-content-center">
      <div
        style={{ minHeight: "90vh" }}
        className="container homepage_container section_divider"
      >
        <div className="row global_search_box">
          <div className="col-md-6 offset-md-3" style={{ zIndex: "50" }}>
            <SearchBox />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 card homepage_center_main_div">
            <div className="row homepagebothicondiv">
              <div className="col-md-6">
                <div className="homepage_icon_div">
                  <div className="row icon_container_row">
                    <div className="col homepage_icon_col">
                      <a
                        onClick={GoToAshomAnalytics}
                        href="about:blank"
                        className="nolink homepage_icon_container card"
                      >
                        <img
                          alt="Image not found."
                          className="ashom_plus_icon"
                          srcSet={ashomAnalytics}
                        />
                      </a>
                    </div>
                  </div>
                  <div className="row">
                    <div className="homepage_icon_title_div">
                      <h3>Ashom Analytics</h3>
                    </div>
                    <span className="homepage_icon_span">
                      Ashom+ is an AI-driven platform simplifying financial
                      analysis for all, with user-friendly tools and intuitive
                      design for smart decision-making.
                    </span>
                  </div>
                  <br />
                </div>
              </div>
              <div className="col-md-6">
                <div className="homepage_icon_div">
                  <div className="row icon_container_row">
                    <div className="col homepage_icon_col">
                      <a
                        onClick={GoToXLSXDownload}
                        href="about:blank"
                        className="nolink homepage_icon_container card"
                      >
                        <img
                          alt="Image not found."
                          className="homepage_icons"
                          srcSet={xlsxDownload}
                        />
                      </a>
                    </div>
                  </div>
                  <div className="row">
                    <div className="homepage_icon_title_div">
                      <h3>XLSX Download</h3>
                    </div>
                    <span className="homepage_icon_span">
                      Download company reports swiftly in XLSX format for
                      streamlined data analysis and decision-making, compatible
                      with major spreadsheet software.
                    </span>
                  </div>
                  <br />
                </div>
              </div>
              {/* <Link to={`/company/${company_id}/download`}>
                                <div className="download_btn_home">
                                    <Button variant="contained">
                                        Screener
                                    </Button>
                                </div>
                            </Link> */}
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        centered
        size="md"
      >
        <Modal.Header>
          <Modal.Title>Ashom Analytics</Modal.Title>
          <button
            type="button"
            className="close"
            //   data-dismiss="modal"
            //   aria-label="Close"
            onClick={handleClose}
          >
            <span aria-hidden="true">
              <img className="close-img" src={closeIcon} alt="Close-Icon" />
            </span>
          </button>
        </Modal.Header>
        <Modal.Body>Coming Soon..!!</Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Companiespage;
